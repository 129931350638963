import {ProSummaryTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ProSummaryTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ProSummaryTypeCd ) {
      this._values.push(ProSummaryTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ProSummaryTypeCd {

    for ( const obj in ProSummaryTypeCd ) {
      if (ProSummaryTypeCd[obj] === value){
        return ProSummaryTypeCd[obj] as ProSummaryTypeCd;
      }
    }
  }
}

const ProSummaryTypeCdHelper = new ProSummaryTypeCdHelperEnumHelperClass();
export default ProSummaryTypeCdHelper;
