import {RouteBalancingTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class RouteBalancingTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RouteBalancingTypeCd ) {
      this._values.push(RouteBalancingTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RouteBalancingTypeCd {

    for ( const obj in RouteBalancingTypeCd ) {
      if (RouteBalancingTypeCd[obj] === value){
        return RouteBalancingTypeCd[obj] as RouteBalancingTypeCd;
      }
    }
  }
}

const RouteBalancingTypeCdHelper = new RouteBalancingTypeCdHelperEnumHelperClass();
export default RouteBalancingTypeCdHelper;
