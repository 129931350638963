import { ErrorHandler, NgModule } from '@angular/core';
import {
  CONFIG_MANAGER_SERVICE_CONFIGURATION,
  ConfigManagerModule,
} from '@xpo-ltl/config-manager';
import { LoggingApiWorkerService } from './logging-api-worker.service';
import { LoggingUserContextHttpInterceptor } from './logging-user-context-http-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoggingHttpInterceptor } from './logging-http-interceptor';
import { GlobalErrorHandlingLogger } from './global-error-handling-logger';



@NgModule({
  imports: [
    ConfigManagerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoggingHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoggingUserContextHttpInterceptor, multi: true },
    { provide: CONFIG_MANAGER_SERVICE_CONFIGURATION, useValue: { configFileUrl: './assets/config.json'}},
    { provide: ErrorHandler, useClass: GlobalErrorHandlingLogger }
  ]
})
export class SdkLoggingModule {
  constructor(private loggingApi: LoggingApiWorkerService) {
    this.loggingApi.init();
  }
}
