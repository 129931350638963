import {ModuleExecutorDataExtractTypCd} from '../';
import {EnumHelper} from './enum-helper';

export class ModuleExecutorDataExtractTypCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ModuleExecutorDataExtractTypCd ) {
      this._values.push(ModuleExecutorDataExtractTypCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ModuleExecutorDataExtractTypCd {

    for ( const obj in ModuleExecutorDataExtractTypCd ) {
      if (ModuleExecutorDataExtractTypCd[obj] === value){
        return ModuleExecutorDataExtractTypCd[obj] as ModuleExecutorDataExtractTypCd;
      }
    }
  }
}

const ModuleExecutorDataExtractTypCdHelper = new ModuleExecutorDataExtractTypCdHelperEnumHelperClass();
export default ModuleExecutorDataExtractTypCdHelper;
