import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DataOptions } from './data-options';
import { NotificationService } from './notification/notification.service';
import { UUID } from 'angular2-uuid';
import { ApiRequest } from './api-request';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DataApiService {
  private correlationId = '';

  constructor(private http: HttpClient,
              private notificationService: NotificationService,
              private configManager: ConfigManagerService) {
    this.correlationId = UUID.UUID();
  }

  /**
   * Unwraps the payload from the API envelope.
   * This will check the response code if provided and ensure it's within the 200-series (not all responses or response types will have it)
   */
  private static extractData(response: any): any {
    const code = response.code;
    if (!code || (code & 200) === 200 || response.access_token) {
      return response.data || response;
    } else {
      return throwError(response.error || response);
    }
  }

  /**
   * Generates a new correlation ID to be used.
   */
  public startNewCorrelationSession() {
    this.correlationId = UUID.UUID();
  }

  public getCorrelationId() {
    return this.correlationId;
  }

  public get(request: ApiRequest): Observable<any> {
    const requestUrl = this.generateFullyQualifiedUrl(request.serviceRootUri, request.queryParamString);
    this.handlePreRequestDuties(request.dataOptions);
    return this.http.get(requestUrl, request.httpOptions as object)
      .pipe(
        map(resp => DataApiService.extractData(resp)),
        catchError(error => this.handleErrors(error, request.dataOptions)),
        finalize(() => this.handlePostRequest(request.dataOptions))
      );
  }

  public post(request: ApiRequest): Observable<any> {
    const requestUrl = this.generateFullyQualifiedUrl(request.serviceRootUri, request.queryParamString);
    this.handlePreRequestDuties(request.dataOptions);
    return this.http.post(requestUrl, request.body, request.httpOptions as object)
      .pipe(
        map(resp => DataApiService.extractData(resp)),
        catchError(error => this.handleErrors(error, request.dataOptions)),
        finalize(() => this.handlePostRequest(request.dataOptions))
      );
  }

  public delete(request: ApiRequest): Observable<any> {
    const requestUrl = this.generateFullyQualifiedUrl(request.serviceRootUri, request.queryParamString);
    this.handlePreRequestDuties(request.dataOptions);
    return this.http.delete(requestUrl, request.httpOptions as object)
      .pipe(
        map(resp => DataApiService.extractData(resp)),
        catchError(error => this.handleErrors(error, request.dataOptions)),
        finalize(() => this.handlePostRequest(request.dataOptions))
      );
  }

  public put(request: ApiRequest): Observable<any> {
    const requestUrl = this.generateFullyQualifiedUrl(request.serviceRootUri, request.queryParamString);
    this.handlePreRequestDuties(request.dataOptions);
    return this.http.put(requestUrl, request.body, request.httpOptions as object)
      .pipe(
        map(resp => DataApiService.extractData(resp)),
        catchError(error => this.handleErrors(error, request.dataOptions)),
        finalize(() => this.handlePostRequest(request.dataOptions))
      );
  }

  private generateFullyQualifiedUrl(url: string = '', queryParams?: string) {
    const configuredApiUrl = this.configManager.getSetting('apiUrl') as string;
    const conditionedApiRoot = `${configuredApiUrl}${configuredApiUrl.endsWith('/') ? '' : '/'}`;
    const conditionedServiceUri = `${url.startsWith('/') ? url.substring(1) : url}`;
    const conditionedQueryParams = (queryParams) ? `?${queryParams}` : '';
    return `${conditionedApiRoot}${conditionedServiceUri}${conditionedQueryParams}`;
  }

  private handleErrors(error: HttpErrorResponse, options: DataOptions): Observable<never> {
    if (options.toastOnError) {
      let duration = 5000;
      if (options && options.snackBarConfig && options.snackBarConfig.durationInMillis) {
        duration = options.snackBarConfig.durationInMillis;
      }
      if (error.status !== undefined && options.errorMessageMap != null && options.errorMessageMap.get(error.status)) {
        this.notificationService.showSnackBarMessage(options.errorMessageMap.get(error.status), {
          status: 'error',
          durationInMillis: duration
        });
      } else if (options.toastErrorMessage) {
        this.notificationService.showSnackBarMessage(options.toastErrorMessage, {
          status: 'error',
          durationInMillis: duration
        });
      } else if (error.error != null && error.error.error != null && error.error.error.message != null) {
        this.notificationService.showSnackBarMessage(`Error: ${error.error.error.message}`, {
          status: 'error',
          durationInMillis: duration
        });
      } else if (error.statusText != null) {
        this.notificationService.showSnackBarMessage(`Error: ${error.statusText}`, {
          status: 'error',
          durationInMillis: duration
        });
      } else {
        this.notificationService.showSnackBarMessage('System error. Please contact support.', {
          status: 'error',
          durationInMillis: duration
        });
      }
    }
    return throwError(error.error || error);
  }

  private handlePreRequestDuties(options: DataOptions): void {
    if (options.startNewCorrelationId) {
      this.startNewCorrelationSession();
    }
    if (options.loadingOverlayEnabled) {
      this.notificationService.showOverlayMessage(options.loadingOverlayMessage);
    }
  }

  private handlePostRequest(options: DataOptions): void {
    if (options.toastOnSuccess) {
      this.notificationService.showSnackBarMessage(options.toastSuccessMessage, options.snackBarConfig);
    }
    if (options.loadingOverlayEnabled) {
      this.notificationService.hideOverlayMessage();
    }
  }
}
