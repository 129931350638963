import {PricingAnalysisRunTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class PricingAnalysisRunTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in PricingAnalysisRunTypeCd ) {
      this._values.push(PricingAnalysisRunTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): PricingAnalysisRunTypeCd {

    for ( const obj in PricingAnalysisRunTypeCd ) {
      if (PricingAnalysisRunTypeCd[obj] === value){
        return PricingAnalysisRunTypeCd[obj] as PricingAnalysisRunTypeCd;
      }
    }
  }
}

const PricingAnalysisRunTypeCdHelper = new PricingAnalysisRunTypeCdHelperEnumHelperClass();
export default PricingAnalysisRunTypeCdHelper;
