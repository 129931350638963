import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import { LoggingApiService } from './logging-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from '@xpo-ltl/data-api';


@Injectable( { providedIn: 'root' } )
export class LoggingApiWorkerService  extends BaseService {
  private static loggingApiEndpoint = 'loggingApiEndpoint';
  private url: string;

  constructor(private configManager: ConfigManagerService,
              private loggingApiService: LoggingApiService,
              private http: HttpClient) {
    super();
  }
  public init() {
    this.loggingApiService.subject.asObservable().subscribe((logRequest) => {
      if (!this.url) {
        this.url = this.createUrl();
      }
      this.http.post(this.url,
        logRequest,
        { headers: new HttpHeaders() })
        .subscribe( results => {}, error => console.log(JSON.stringify(error)));
    });
  }
  private createUrl() {
    const apiUrl = this.configManager.getSetting<string>('apiUrl');
    const url = this.getUrl('logs');
    return `${apiUrl}${apiUrl.endsWith('/') ? '' : '/'}${url.startsWith('/') ? url.substring(1) : url}`;
  }
  protected getEndPoint(): string {
    return this.configManager.getSetting(LoggingApiWorkerService.loggingApiEndpoint);
  }

}
