/**
 * HumanResource API
 * Version: 1.0
 * Build: Manual
 */

import {
	ListInfo,
	AuditInfo,
	Email,
	Phone,
	ActionCd,
	EmployeeIdTypeCd
} from '@xpo-ltl/sdk-common';

/**
 * Insert a new record for a Kronos punch activity request type
 */
export class CreateEmployeePunchActivityRecordRqst {
	/**
	 * Basic punch time information
	 * Required: false
	 * Array: false
	 */
	punchAcitvity:EmployeePunchActivity = undefined;
	/**
	 * More details about the punch activity recorded by Kronos for reporting and payroll.
	 * Required: false
	 * Array: false
	 */
	punchAcitivityAdditionalInfo:EmployeePunchActivityInfo = undefined;
}
/**
 * Insert a new record for a Kronos punch activity response type
 */
export class CreateEmployeePunchActivityRecordResp {
	/**
	 * Composite key for the record that was created.
	 * Required: false
	 * Array: false
	 */
	punchRecordKey:EmployeePunchActivityKey = undefined;
}
/**
 * Inserts a list of Kronos punch activities in the WFM database. The raw events will be interpreted and an ltlTimeCardEvent assigned to those that have been identified for use in various contexts. The entire input list will be returned, with the additional event type code if one was identified.
 */
export class CreateEmployeePunchActivityRecordsRqst {
	/**
	 * The list of raw Kronos punch activities to be stored in the WFM database.
	 * Required: false
	 * Array: true
	 */
	rawEmployeePunchActivityRecords:CreateEmployeePunchActivityRecordRqst[] = undefined;
}
/**
 * Inserts a list of Kronos punch activities in the WFM database. The raw events will be interpreted and an ltlTimeCardEvent assigned to those that have been identified for use in various contexts. The entire input list will be returned, with the additional event type code if one was identified.
 */
export class CreateEmployeePunchActivityRecordsResp {
	/**
	 * The list of employee punch activities that was passed in to the request with the addition of the ltlTimeCardEventTypeCd if one was assigned.
	 * Required: false
	 * Array: true
	 */
	createdPunchActivities:CreateEmployeePunchActivityRecordRqst[] = undefined;
}
/**
 * Insert a new record for an employee lunch start or end time
 */
export class CreateEmployeeLunchRecordRqst {
	/**
	 * Basic punch time information
	 * Required: false
	 * Array: false
	 */
	punchAcitvity:EmployeePunchActivity = undefined;
}
/**
 * Insert a new record for an employee lunch start or end time response. The punch activity key is returned.
 */
export class CreateEmployeeLunchRecordResp {
	/**
	 * Composite key for the record that was created.
	 * Required: false
	 * Array: false
	 */
	punchRecordKey:EmployeePunchActivityKey = undefined;
}
/**
 * Insert a new record for a dsr employee's trip details request
 */
export class CreateEmployeeDsrTripRecordRqst {
	/**
	 * Basic punch time information
	 * Required: false
	 * Array: false
	 */
	dsrTripDetails:EmployeeDsrTrip = undefined;
}
/**
 * Insert a new record for a dsr employee's trip details response
 */
export class CreateEmployeeDsrTripRecordResp {
	/**
	 * Composite key for the record that was created.
	 * Required: false
	 * Array: false
	 */
	punchRecordKey:EmployeePunchActivityKey = undefined;
}
/**
 * Response to the create request for a role
 */
export class CreateRoleResp {
	/**
	 * Role details
	 * Required: false
	 * Array: false
	 */
	role:Role = undefined;
}
/**
 * Request to create a new role.
 */
export class CreateRoleRqst {
	/**
	 * Role details
	 * Required: false
	 * Array: false
	 */
	role:Role = undefined;
}
/**
 * Response to the list request for roles.
 */
export class ListRolesResp {
	/**
	 * List of roles
	 * Required: false
	 * Array: true
	 */
	roles:Role[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to list roles 
 */
export class ListRolesRqst {
	/**
	 * Unique identifier for the role
	 * Required: false
	 * Array: false
	 */
	roleId:number = undefined;
	/**
	 * Name of the role
	 * Required: false
	 * Array: false
	 */
	roleName:string = undefined;
	/**
	 * Description of the role
	 * Required: false
	 * Array: false
	 */
	roleDescription:string = undefined;
	/**
	 * The date the role becomes effective
	 * Required: false
	 * Array: false
	 */
	effectiveDate:Date = undefined;
	/**
	 * Indicates whether the role is a primary role
	 * Required: false
	 * Array: false
	 */
	primaryRoleInd:boolean = undefined;
	/**
	 * The numeric ranking of the role in the hierarchy of roles
	 * Required: false
	 * Array: false
	 */
	roleLevel:number = undefined;
	/**
	 * Code value assigned to the role
	 * Required: false
	 * Array: false
	 */
	roleCode:string = undefined;
	/**
	 * Code value indicating the category the role falls into
	 * Required: false
	 * Array: false
	 */
	categoryCode:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to list the secondary roles associated with a job code.
 */
export class ListSecondaryRolesByJobCodeRqst {
	/**
	 * Code identifying the job position.
	 * Required: true
	 * Array: false
	 */
	jobCode:string = undefined;
	/**
	 * Pagination infromation to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to request to list the secondary roles associated with a job code.
 */
export class ListSecondaryRolesByJobCodeResp {
	/**
	 * List of secondary roles.
	 * Required: false
	 * Array: true
	 */
	secondaryRoles:SecondaryRoles[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to update a role
 */
export class UpdateRoleRqst {
	/**
	 * Role details
	 * Required: false
	 * Array: false
	 */
	role:Role = undefined;
}
/**
 * Response to the update request for a role.
 */
export class UpdateRoleResp {
	/**
	 * Role details
	 * Required: false
	 * Array: false
	 */
	role:Role = undefined;
}
/**
 * Response to the retrieve request to return employee details for an employee id
 */
export class GetEmployeeDetailsByEmpIdResp {
	/**
	 * Detailed information about an employee
	 * Required: false
	 * Array: false
	 */
	employee:Employee = undefined;
	/**
	 * The code associated with the district.
	 * Required: false
	 * Array: false
	 */
	district:string = undefined;
	/**
	 * The name of the district.
	 * Required: false
	 * Array: false
	 */
	districtName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Request to retrieve employee details using employee id
 */
export class GetEmployeeDetailsByEmpIdRqst {
	/**
	 * Unique Identifier for the employee
	 * Required: true
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * The effective date of the employee's info (on HSF_JOB).
	 * Required: false
	 * Array: false
	 */
	effectiveDate:Date = undefined;
	/**
	 * Optional Input. If requestType = Basic then only the basic details of the employee will be returned. If requestType is not provided then full details of the employee will be returned
	 * Required: false
	 * Array: false
	 */
	requestType:string = undefined;
}
/**
 * Response to the retrieve request to return employee job info for a driver employee id
 */
export class GetEmployeeJobInfoForDriverResp {
	/**
	 * Unique identifier for an employee
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * The effective date for the employee's job.
	 * Required: true
	 * Array: false
	 */
	effectiveDate:Date = undefined;
	/**
	 * The domicile Sic for the employee
	 * Required: false
	 * Array: false
	 */
	deptSic:string = undefined;
	/**
	 * Code identifying the company the employee is employed by
	 * Required: false
	 * Array: false
	 */
	companyCd:string = undefined;
	/**
	 * Identifier for the payroll group the employee is assigned to
	 * Required: false
	 * Array: false
	 */
	payGroup:string = undefined;
	/**
	 * The information that will be returned to explain why an employee failed the criteria used to select a driver.
	 * Required: false
	 * Array: false
	 */
	errorCondition:string = undefined;
	/**
	 * The first name of the employee.
	 * Required: false
	 * Array: false
	 */
	firstName:string = undefined;
	/**
	 * The middle initial of the employee.
	 * Required: false
	 * Array: false
	 */
	middleInitial:string = undefined;
	/**
	 * The last name of the employee.
	 * Required: false
	 * Array: false
	 */
	lastName:string = undefined;
}
/**
 * Request to retrieve employee job info using driver employee id
 */
export class GetEmployeeJobInfoForDriverRqst {
	/**
	 * Unique Identifier for the employee
	 * Required: true
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * The effective date of the employee's info (on HSF_JOB).
	 * Required: false
	 * Array: false
	 */
	effectiveDate:Date = undefined;
}
/**
 * Response returning the last activity for the requested employee
 */
export class GetLatestActivityForEmployeeResp {
	/**
	 * The last punch activity for the specified employee
	 * Required: false
	 * Array: false
	 */
	employeePunchActivity:EmployeePunchActivity = undefined;
}
/**
 * Request for the last punch activity for the employee
 */
export class GetLatestActivityForEmployeeRqst {
	/**
	 * Unique Identifier for the employee
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
}
/**
 * Response to the retrieve request to return employee details for an employee id
 */
export class ListEmailAddressesForEmployeeResp {
	/**
	 * Unique identifier for the employee
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	emailAddress:Email = undefined;
	/**
	 * Indicates whether the email address is the primary email for the employee
	 * Required: false
	 * Array: false
	 */
	preferredEmailAddressInd:boolean = undefined;
}
/**
 * Request to retrieve the designated primary email address using employee id
 */
export class ListEmailAddressesForEmployeeRqst {
	/**
	 * Unique Identifier for the employee
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * Indicates whether to retrieve only the preferred email address for the employee
	 * Required: false
	 * Array: false
	 */
	preferredEmailAddressInd:boolean = undefined;
}
/**
 * Response to the retrieve request to return system access information for an employee id
 */
export class GetSystemAccessForEmployeeResp {
	/**
	 * System Access details for the employee
	 * Required: false
	 * Array: false
	 */
	systemAccess:EmployeeSystemAccess = undefined;
}
/**
 * Request to retrieve the system Id information using employee id
 */
export class GetSystemAccessForEmployeeRqst {
	/**
	 * Unique Identifier for the employee
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
}
/**
 * Response to the retrieve request to return system id information for an employee id
 */
export class GetSystemIdDetailsForEmployeeResp {
	/**
	 * System Id details for the employee
	 * Required: false
	 * Array: false
	 */
	systemId:EmployeeSystemId = undefined;
}
/**
 * Request to retrieve the system access information using employee id
 */
export class GetSystemIdDetailsForEmployeeRqst {
	/**
	 * Unique Identifier for the employee
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
}
/**
 * Response to the request to list direct report employee details for a manager's employee id
 */
export class ListDirectReportEmployeesResp {
	/**
	 * Detailed information about a manager's direct report employees
	 * Required: false
	 * Array: true
	 */
	directReportEmployees:DirectReportEmployee[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to list direct report employee details for a manager's employee id
 */
export class ListDirectReportEmployeesRqst {
	/**
	 * Unique Identifier for the supervisor.
	 * Required: false
	 * Array: false
	 */
	supervisorId:string = undefined;
	/**
	 * Unique Identifier for the manager employee
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * Type of employees to be returned. S (Superuser), M(Manager), R(RACF admin), H (helpdesk admin)
	 * Required: false
	 * Array: false
	 */
	employeeType:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list dsr employees based on input criteria
 */
export class ListDsrEmployeesResp {
	/**
	 * List of employee driver sales representative (dsr)
	 * Required: false
	 * Array: true
	 */
	dsrEmployees:DsrEmployee[] = undefined;
}
/**
 * Request to list dsr employees based on input criteria
 */
export class ListDsrEmployeesRqst {
	/**
	 * The service center location for the dsr employee
	 * Required: false
	 * Array: false
	 */
	locationSic:string = undefined;
	/**
	 * The starting last name to be used in filtering the dsr employee list
	 * Required: false
	 * Array: false
	 */
	startingDsrLastName:string = undefined;
}
/**
 * List all of the employee punches that have occurred since the provided date and time
 */
export class ListEmployeePunchesSinceDateTimeRqst {
	/**
	 * Date for punch activity request
	 * Required: false
	 * Array: false
	 */
	requestDate:Date = undefined;
	/**
	 * Time for punch activity request
	 * Required: false
	 * Array: false
	 */
	requestTime:Date = undefined;
	/**
	 * Flag indicating that the list should be filtered by this field. If false, all records that have not yet been sent to the eobr vendor will be returned.
	 * Required: false
	 * Array: false
	 */
	sentToEobrInd:boolean = undefined;
	/**
	 * pagination information to use for the list management
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * List all of the employee punches that have occurred since the provided date and time
 */
export class ListEmployeePunchesSinceDateTimeResp {
	/**
	 * Punch activity records that match the date and time criteria
	 * Required: false
	 * Array: true
	 */
	employeePunches:EmployeePunchActivity[] = undefined;
	/**
	 * pagination information to manage the list of objects
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * List all of the lunch punches that have occurred since the provided date and time
 */
export class ListLunchPunchesSinceDateTimeRqst {
	/**
	 * Date for punch activity request
	 * Required: false
	 * Array: false
	 */
	requestDate:Date = undefined;
	/**
	 * Time for punch activity request
	 * Required: false
	 * Array: false
	 */
	requestTime:Date = undefined;
	/**
	 * Flag indicating that the list should be filtered by this field. If false, all records that have not yet been sent to the eobr vendor will be returned.
	 * Required: false
	 * Array: false
	 */
	sentToEobrInd:boolean = undefined;
	/**
	 * pagination information to use for the list
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * List all of the lunch punches that have occurred since the provided date and time
 */
export class ListLunchPunchesSinceDateTimeResp {
	/**
	 * lunch punch activity records that match the time criteria
	 * Required: false
	 * Array: true
	 */
	employeeLunchPunches:EmployeePunchActivity[] = undefined;
	/**
	 * pagination information to use for the managing the list of objects
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to list all DSR trip activities that have occurred since the requested date and time
 */
export class ListDsrTripsSinceDateTimeRqst {
	/**
	 * DateTime for the dispatch activity
	 * Required: false
	 * Array: false
	 */
	dispatchDateTime:Date = undefined;
	/**
	 * To filter for record that have been sent set this value to TRUE , for not sent set it to FALSE, or ALL for no filter. If it is null, implied ALL
	 * Required: false
	 * Array: false
	 */
	sentToEobrInd:string = undefined;
	/**
	 * pagination information to use for the list
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response object for listing all Dsr activities since the provided date and time
 */
export class ListDsrTripsSinceDateTimeResp {
	/**
	 * DSR trip activity records that match the time criteria
	 * Required: false
	 * Array: true
	 */
	employeeDsrTrip:EmployeeDsrTrip[] = undefined;
	/**
	 * pagination information to use for the managing the list of objects
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the list request for employee roles.
 */
export class ListRolesForEmployeeResp {
	/**
	 * List of employees and their assigned roles
	 * Required: false
	 * Array: true
	 */
	employeeRoles:EmployeeRole[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to list the roles assigned to an employee.
 */
export class ListRolesForEmployeeRqst {
	/**
	 * Unique identifier for the employee
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
}
/**
 * Response to the list request for employee security questions and answers.
 */
export class ListSecurityQuestionsForEmployeeResp {
	/**
	 * List of Answers to set Security Questions for Employee
	 * Required: false
	 * Array: true
	 */
	employeeSecurityQuestions:EmployeeSecurityQuestion[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to list the employee security questions and answers.
 */
export class ListSecurityQuestionsForEmployeeRqst {
	/**
	 * Unique identifier for the employee
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
}
/**
 * Set the sentTotEobrInd in the punch activity record identified by the keyto true
 */
export class SetSentToEobrIndRqst {
	/**
	 * Basic punch time information
	 * Required: false
	 * Array: true
	 */
	punchAcitvityKey:EmployeePunchActivityKey[] = undefined;
}
/**
 * Request to update employee role assignments
 */
export class UpdateRolesForEmployeeRqst {
	/**
	 * Role details for the employee
	 * Required: false
	 * Array: true
	 */
	employeeRoles:EmployeeRole[] = undefined;
}
/**
 * Request to update security questions and answers for an employee.
 */
export class UpdateSecurityQuestionsForEmployeeRqst {
	/**
	 * Security questions and answers for an employee
	 * Required: false
	 * Array: true
	 */
	employeeSecurityQuestions:EmployeeSecurityQuestion[] = undefined;
}
/**
 * Request to update system access requirements for an employee
 */
export class UpdateSystemAccessForEmployeeRqst {
	/**
	 * System Access details for an employee
	 * Required: false
	 * Array: false
	 */
	systemAccess:EmployeeSystemAccess = undefined;
	/**
	 * System Access details for an employee
	 * Required: true
	 * Array: false
	 */
	currentSuperUserInd:boolean = undefined;
	/**
	 * System Access details for an employee
	 * Required: true
	 * Array: false
	 */
	oldSuperUserInd:boolean = undefined;
}
/**
 * Request to update system id details for an employee
 */
export class UpdateSystemIdDetailsForEmployeeRqst {
	/**
	 * System Id details for an employee
	 * Required: false
	 * Array: false
	 */
	systemId:EmployeeSystemId = undefined;
}
/**
 * Request to list job codes and their assigned role.
 */
export class ListJobCodeRoleAssignmentsRqst {
	/**
	 * Code identifying the job position.
	 * Required: false
	 * Array: false
	 */
	jobCode:string = undefined;
	/**
	 * Description of the job position.
	 * Required: false
	 * Array: false
	 */
	jobDescription:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list job codes and their assigned role.
 */
export class ListJobCodeRoleAssignmentsResp {
	/**
	 * List of job codes and their assigned role.
	 * Required: false
	 * Array: true
	 */
	jobCodes:JobCode[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to update (save or remove) job codes and their assigned role.
 */
export class UpdateSecondaryRolesByJobCodeRqst {
	/**
	 * Used to update secondary roles associated with a job code.
	 * Required: true
	 * Array: true
	 */
	secondaryRolesToJobCode:SecondaryRolesToJobCode[] = undefined;
}
/**
 * Request to update Job Codes and their assigned role.
 */
export class UpdateJobCodeRoleAssignmentsRqst {
	/**
	 * Job codes and their role assignment.
	 * Required: true
	 * Array: true
	 */
	jobCodes:JobCode[] = undefined;
}
/**
 * Request to determine if the provided employee ID belongs to a driver.
 */
export class IsEmployeeADriverRqst {
	/**
	 * The unique identifier for the employee.
	 * Required: true
	 * Array: false
	 */
	employeeId:string = undefined;
}
/**
 * Response to the request to determine if the provided employee ID belongs to a driver.
 */
export class IsEmployeeADriverResp {
	/**
	 * Indicates whether or not an employee is a driver.
	 * Required: false
	 * Array: false
	 */
	isEmployeeADriver:boolean = undefined;
}
/**
 * Request for Job Codes with default primary role
 */
export class ListJobCodesWithDefaultPrimaryRoleIdRqst {
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the list request for Job Codes with default primary role
 */
export class ListJobCodesWithDefaultPrimaryRoleIdResp {
	/**
	 * List of Job Codes and their assigned role.
	 * Required: false
	 * Array: true
	 */
	hsfJobcodes:HsfJobcode[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to list the employees assigned to a Role Id
 */
export class ListEmployeesByRoleIdRqst {
	/**
	 * Unique identifier for the Role
	 * Required: false
	 * Array: false
	 */
	roleId:string = undefined;
}
/**
 * Response to the list request for employees for a Role Id.
 */
export class ListEmployeesByRoleIdResp {
	/**
	 * List of employees for the Role Id
	 * Required: false
	 * Array: true
	 */
	employeeNames:EmployeeName[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to determine, and return, if there is a corresponding People Soft employee ID for the Success Factors employee ID provided, and if there isn't create one and return it.
 */
export class DetermineLtlEmployeeIdRqst {
	/**
	 * The 8 character Succes Factors employee ID.
	 * Required: true
	 * Array: false
	 */
	successFactorsEmployeeId:string = undefined;
	/**
	 * Indicates if a corresponding People Soft employee ID exists.
	 * Required: true
	 * Array: false
	 */
	isXrefCreated:boolean = undefined;
	/**
	 * Audit trail information.
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Response to the request to determine, and return, if there is a corresponding People Soft employee ID for the Success Factors employee ID provided, and if there isn't create one and return it.
 */
export class DetermineLtlEmployeeIdResp {
	/**
	 * The 8 character Succes Factors employee ID.
	 * Required: false
	 * Array: false
	 */
	successFactorsEmployeeId:string = undefined;
	/**
	 * The 5 character People Soft employee ID.
	 * Required: false
	 * Array: false
	 */
	peopleSoftEmployeeId:string = undefined;
	/**
	 * Identifies if the cross reference was created by People Soft or XPO.
	 * Required: false
	 * Array: false
	 */
	peopleSoftEmployeeSourceCd:string = undefined;
}
/**
 * Request to retrieve the People Soft or Success Factors employee ID cross reference.
 */
export class GetEmployeeIdXrefRqst {
	/**
	 * The 5 character People Soft employee ID.
	 * Required: false
	 * Array: false
	 */
	peopleSoftEmployeeId:string = undefined;
	/**
	 * The 8 character Succes Factors employee ID.
	 * Required: false
	 * Array: false
	 */
	successFactorsEmployeeId:string = undefined;
	/**
	 * Audit trail information.
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Response to the request to retrieve either the People Soft or Success Factors employee ID cross reference.
 */
export class GetEmployeeIdXrefResp {
	/**
	 * The 5 character People Soft employee ID.
	 * Required: false
	 * Array: false
	 */
	peopleSoftEmployeeId:string = undefined;
	/**
	 * The 8 character Succes Factors employee ID.
	 * Required: false
	 * Array: false
	 */
	successFactorsEmployeeId:string = undefined;
	/**
	 * Identifies if the cross reference was created by People Soft or XPO.
	 * Required: false
	 * Array: false
	 */
	peopleSoftEmployeeSourceCd:string = undefined;
}
/**
 * Request to retrieve HRS employee details using employee id.
 */
export class GetHrsEmployeeDetailsByEmpIdRqst {
	/**
	 * Unique Identifier for the employee
	 * Required: true
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * If this field isn't populated, then the employeeId is used. ï¿½Otherwise, the type, e.g. racfId, must be specified. ï¿½The racfId will need to be validated.
	 * Required: false
	 * Array: false
	 */
	employeeIdType:EmployeeIdTypeCd = undefined;
}
/**
 * Response to the retrieve request to return HRS employee details for an employee id.
 */
export class GetHrsEmployeeDetailsByEmpIdResp {
	/**
	 * Detailed information about an employee
	 * Required: false
	 * Array: false
	 */
	employee:Employee = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Request to retrieve the full set of employee details using employee id.  For use only be Role and Access Management application and its downstream consumers
 */
export class GetFullEmployeeDetailsByEmpIdRqst {
	/**
	 * Unique Identifier for the employee
	 * Required: true
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * The unique identifier for the person calling the API.
	 * Required: false
	 * Array: false
	 */
	requesterId:string = undefined;
	/**
	 * The effective date of the employee's info (on HSF_JOB).
	 * Required: false
	 * Array: false
	 */
	effectiveDate:Date = undefined;
}
/**
 * Response to the retrieve request to return the full set of employee details using employee id.  For use only be Role and Access Management application and its downstream consumers
 */
export class GetFullEmployeeDetailsByEmpIdResp {
	/**
	 * Detailed information about an employee
	 * Required: false
	 * Array: false
	 */
	personalData:PersonalData = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	emailAddress:EmployeeEmailAddress = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	employeeLocation:EmployeeLocation = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	neeProvider:NeeProvider = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	job:Job = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	personOrgAssignment:PersonOrgAssignment = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	phones:Phone[] = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	roles:EmployeeRole[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	employeeSystemAccess:EmployeeSystemAccess = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	employeeSystemId:EmployeeSystemId = undefined;
	/**
	 * Detailed information about the employee who called the API.
	 * Required: false
	 * Array: false
	 */
	requesterData:PersonalData = undefined;
}
/**
 * Request to retrieve the system access information using the domain and user ID.
 */
export class GetSystemIdDetailsByDomainAndUserIdRqst {
	/**
	 * The employee domain (e.g. CONWAY).
	 * Required: true
	 * Array: false
	 */
	domain:string = undefined;
	/**
	 * The Windows user ID (e.g. [first initial of employee's first name]x[last name of employee]).
	 * Required: true
	 * Array: false
	 */
	userId:string = undefined;
}
/**
 * Response to the request to return system access information by domain and user ID.
 */
export class GetSystemIdDetailsByDomainAndUserIdResp {
	/**
	 * System Id details for the employee
	 * Required: false
	 * Array: false
	 */
	systemId:EmployeeSystemId = undefined;
}
/**
 * Request to list employees based on the employee ID or the first and last name filter criteria.
 */
export class ListEmployeesByNameRqst {
	/**
	 * The first name of the employee.
	 * Required: false
	 * Array: false
	 */
	firstName:string = undefined;
	/**
	 * The last name of the employee.
	 * Required: true
	 * Array: false
	 */
	lastName:string = undefined;
	/**
	 * The location of the employee(s).
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The HR role codes associated with the employee(s).
	 * Required: false
	 * Array: true
	 */
	roleCd:string[] = undefined;
}
/**
 * Response to the request to list employees who met the provided filter criteria.
 */
export class ListEmployeesByNameResp {
	/**
	 * List of employee personal data and job position info.
	 * Required: false
	 * Array: true
	 */
	employees:EmployeeListItem[] = undefined;
	/**
	 * Indicates whether or not there are more data than were returned in the result.
	 * Required: false
	 * Array: false
	 */
	hasMoreData:boolean = undefined;
}
export class ListEmployeesByJobCodeRqst {
	/**
	 * List of Job Codes
	 * Required: true
	 * Array: true
	 */
	jobCode:string[] = undefined;
	/**
	 * Used so that only updated employees are returned.
	 * Required: false
	 * Array: false
	 */
	beginningDate:Date = undefined;
	/**
	 * Pagination information to retrieve list
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
export class ListEmployeesByJobCodeResp {
	/**
	 * Required: false
	 * Array: true
	 */
	employeeJobRole:EmployeeJobRole[] = undefined;
}
export class GetEmployeeCountByJobCodeRqst {
	/**
	 * The unique identifier associated with a particular position.
	 * Required: true
	 * Array: false
	 */
	jobCode:string = undefined;
}
export class GetEmployeeCountByJobCodeResp {
	/**
	 * The number of employees.
	 * Required: false
	 * Array: false
	 */
	employeeCount:number = undefined;
}
/**
 * Response to the request to list employees who met the provided filter criteria.
 */
export class ListLatestRoleTypeEventLogsRqst {
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
export class ListLatestRoleTypeEventLogsResp {
	/**
	 * Info from IDM Event Logs.
	 * Required: false
	 * Array: true
	 */
	idmEventLog:IdmEventLog[] = undefined;
}
export class ProcessEmployeeRolesEffectiveTodayRqst {
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
export class ProcessEmployeeRolesEffectiveTodayResp {
	/**
	 * Boolean value to identify if the batch process was successful.
	 * Required: false
	 * Array: false
	 */
	isSuccessful:boolean = undefined;
}
export class ProcessEmployeeRolesExpiringTodayRqst {
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
export class ProcessEmployeeRolesExpiringTodayResp {
	/**
	 * Boolean value to identify if the batch process was successful.
	 * Required: false
	 * Array: false
	 */
	isSuccessful:boolean = undefined;
}
export class DetermineContractorEmployeeConversionRqst {
	/**
	 * Employee's Success Factors employee ID.
	 * Required: true
	 * Array: false
	 */
	sfEmplId:string = undefined;
}
export class DetermineContractorEmployeeConversionResp {
	/**
	 * Indicates if the employee is a contractor-to-employee conversion.
	 * Required: false
	 * Array: false
	 */
	isCteConversionEmployee:boolean = undefined;
}
export class GetEmployeeCountByRoleRqst {
	/**
	 * The unique identifier for the role record.
	 * Required: true
	 * Array: false
	 */
	role:string = undefined;
}
export class GetEmployeeCountByRoleResp {
	/**
	 * The number of employees associated with the role.
	 * Required: false
	 * Array: false
	 */
	employeeCount:number = undefined;
}
/**
 * Request to list employees by the employee IDs provided.
 */
export class ListEmployeesByEmpIdRqst {
	/**
	 * Unique identifier for the employee record.
	 * Required: true
	 * Array: true
	 */
	employeeId:string[] = undefined;
}
/**
 * Response to the request to list employees by the employee IDs provided.
 */
export class ListEmployeesByEmpIdResp {
	/**
	 * List of employee name information.
	 * Required: false
	 * Array: true
	 */
	employeeNames:EmployeeName[] = undefined;
}
/**
 * Request to search for employee details (name and ID) of the DSRs based on the SIC, search string, and zones indicated.
 */
export class SearchEmployeeDetailsBySicRqst {
	/**
	 * Location code to search for DSR.
	 * Required: true
	 * Array: false
	 */
	locationSic:string = undefined;
	/**
	 * The string which will be entered as the search criteria.This string can be part of the driver's first, middle, or last name.
	 * Required: true
	 * Array: false
	 */
	searchString:string = undefined;
	/**
	 * Indicates if the name should be searched from the zones as well.
	 * Required: true
	 * Array: false
	 */
	includeZones:boolean = undefined;
}
/**
 * Response to the request to search for employee details (name and ID) of the DSRs based on the SIC, search string, and zones indicated.
 */
export class SearchEmployeeDetailsBySicResp {
	/**
	 * A list of drivers that match the input provided.
	 * Required: false
	 * Array: true
	 */
	dsrEmployees:DsrEmployee[] = undefined;
}
/**
 * Request to return the domicile SIC for an employee along with a flag that indicates whether they are a driver or not.
 */
export class GetDomicileSicForEmployeeRqst {
	/**
	 * The unique identifier for the employee record.
	 * Required: true
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * Effective date of the employee info (on HSF_JOB).
	 * Required: false
	 * Array: false
	 */
	effectiveDate:Date = undefined;
}
/**
 * Response to the request to return the domicile SIC for an employee along with a flag that indicates whether they are a driver or not.
 */
export class GetDomicileSicForEmployeeResp {
	/**
	 * Information about an employee and their domicile.
	 * Required: false
	 * Array: false
	 */
	employeeDomicile:EmployeeDomicile = undefined;
}
/**
 * Request to return a list of employees by job code for elogging.
 */
export class ListElogEmployeesByJobCodeRqst {
	/**
	 * List of job codes.
	 * Required: true
	 * Array: true
	 */
	jobCode:string[] = undefined;
	/**
	 * The date for which the user wants the search to start at.
	 * Required: false
	 * Array: false
	 */
	beginningDate:Date = undefined;
}
/**
 * Response to the request to return a list of employees by job code for elogging.
 */
export class ListElogEmployeesByJobCodeResp {
	/**
	 * List of elog employee information.
	 * Required: false
	 * Array: true
	 */
	elogEmployeeJobRole:ElogEmployeeJobRole[] = undefined;
}
/**
 * Request to return a list of employees by SIC.
 */
export class ListEmployeesBySicRqst {
	/**
	 * The location of the employee(s).
	 * Required: true
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * The string which will be entered as the search criteria. ï¿½This string can be part of the driver's employee ID as well as their first, middle, or last name.
	 * Required: false
	 * Array: false
	 */
	searchString:string = undefined;
	/**
	 * The HR role codes associated with the employee(s).
	 * Required: false
	 * Array: true
	 */
	roleCd:string[] = undefined;
}
/**
 * Response to the request to return a list of employees by SIC.
 */
export class ListEmployeesBySicResp {
	/**
	 * List of employees that match the input search criteria.
	 * Required: false
	 * Array: true
	 */
	employeeListItem:EmployeeListItem[] = undefined;
	/**
	 * Indicates whether or not there are more data than were returned in the result.
	 * Required: false
	 * Array: false
	 */
	hasMoreDataInd:boolean = undefined;
}
/**
 * Information about a manager's direct rerport employee
 */
export class DirectReportEmployee {
	/**
	 * Unique Identifier for the direct report employee
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * First name of the direct report employee
	 * Required: false
	 * Array: false
	 */
	firstName:string = undefined;
	/**
	 * Last name of the direct report employee
	 * Required: false
	 * Array: false
	 */
	lastName:string = undefined;
	/**
	 * Identifies the type of work the direct report employee performs
	 * Required: false
	 * Array: false
	 */
	jobCode:string = undefined;
	/**
	 * Description of the job position for the direct report employee
	 * Required: false
	 * Array: false
	 */
	jobDescription:string = undefined;
	/**
	 * The location of the employee.
	 * Required: false
	 * Array: false
	 */
	employeeDomicileSicCd:string = undefined;
	/**
	 * The HR employee's status.
	 * Required: false
	 * Array: false
	 */
	hrStatus_:string = undefined;
	public toJSON = function(this: DirectReportEmployee) {
		return {
			"employeeId" : this.employeeId,
			"firstName" : this.firstName,
			"lastName" : this.lastName,
			"jobCode" : this.jobCode,
			"jobDescription" : this.jobDescription,
			"employeeDomicileSicCd" : this.employeeDomicileSicCd,
			"hrStatus." : this.hrStatus_		}
	}
}
/**
 * Employee information about a driver sales representative (dsr)
 */
export class DsrEmployee {
	/**
	 * Unique Identifier for the dsr employee
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * Full name of the dsr employee
	 * Required: false
	 * Array: false
	 */
	fullName:string = undefined;
	/**
	 * First name of the dsr employee
	 * Required: false
	 * Array: false
	 */
	firstName:string = undefined;
	/**
	 * Last name of the dsr employee
	 * Required: false
	 * Array: false
	 */
	lastName:string = undefined;
	/**
	 * The service center location for the dsr employee
	 * Required: false
	 * Array: false
	 */
	locationSic:string = undefined;
	/**
	 * The driver's license number for the dsr employee (sometimes referred to as the fast_id)
	 * Required: false
	 * Array: false
	 */
	licenseNbr:string = undefined;
}
/**
 * Basic Information about Employee Identification
 */
export class EmployeeIdBasic {
	/**
	 * This is the original id of the person when a non
	 * employee becomes an employee.
	 * Required: false
	 * Array: false
	 */
	alterEmployeeId:string = undefined;
	/**
	 * Unique Identifier for the employee
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * First name of the employee
	 * Required: false
	 * Array: false
	 */
	firstName:string = undefined;
	/**
	 * Last name of the employee
	 * Required: false
	 * Array: false
	 */
	lastName:string = undefined;
	/**
	 * Middle initial of the employee
	 * Required: false
	 * Array: false
	 */
	middleInitial:string = undefined;
	/**
	 * Email address assigned to the employee
	 * Required: false
	 * Array: false
	 */
	emailAddress:string = undefined;
}
/**
 * Basic information about an employee
 */
export class EmployeeBasic {
	/**
	 * The domicile Sic for the employee
	 * Required: false
	 * Array: false
	 */
	deptSic:string = undefined;
	/**
	 * Unique Identifier for the employee
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * Code identifying whether the employee is hourly or salaried.
	 * Required: false
	 * Array: false
	 */
	employeeTypeCd:string = undefined;
	/**
	 * First name of the employee
	 * Required: false
	 * Array: false
	 */
	firstName:string = undefined;
	/**
	 * Determines whether an employee has been terminated.
	 * Required: false
	 * Array: false
	 */
	isTerminated:boolean = undefined;
	/**
	 * Last name of the employee
	 * Required: false
	 * Array: false
	 */
	lastName:string = undefined;
	/**
	 * Middle initial of the employee
	 * Required: false
	 * Array: false
	 */
	middleInitial:string = undefined;
	/**
	 * Preferred first name of the employee
	 * Required: false
	 * Array: false
	 */
	preferredFirstName:string = undefined;
	/**
	 * The Success Factors (SF) 8 character employee ID.
	 * Required: false
	 * Array: false
	 */
	sfEmployeeId:string = undefined;
	/**
	 * The effective date of an employee's termination with the company.
	 * Required: false
	 * Array: false
	 */
	terminationDate:Date = undefined;
	/**
	 * A two character code that the DMS team uses to assign the employees payroll processing to the correct batch. E.g. Canadian, Machine (normal), or Special.  It determines the way that employees' pay is processed.
	 * Required: false
	 * Array: false
	 */
	payGroup:string = undefined;
	/**
	 * The type of special user which the employee is identified as being, e.g. Managers, HR 'super users', RACF Admins, and Help Desk employees.  userType will only be populated if the requestType parameter is set to ï¿½userï¿½.
	 * Required: false
	 * Array: false
	 */
	userType:string = undefined;
	/**
	 * Type of employment: F(Full Time) or P(Part Time)
	 * Required: false
	 * Array: false
	 */
	employmentTypeCd:string = undefined;
	/**
	 * Driver Type: valid values are LTLCD (city driver) and LTLLD (linehaul driver)
	 * Required: false
	 * Array: false
	 */
	driverTypeCd:string = undefined;
}
/**
 * Detailed information about an employee
 */
export class Employee {
	/**
	 * This is the original id of the person when a non
	 * employee becomes an employee.
	 * Required: false
	 * Array: false
	 */
	alterEmployeeId:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Unique Identifier for the employee
	 * Required: false
	 * Array: false
	 */
	basicInfo:EmployeeBasic = undefined;
	/**
	 * Benefit program code for the employee.  Example Valid Values - FTC- Con-Way  Full Time Regular Hourly  Salaried, Associates, FTD- Con-Way Truckload CTL Drivers, FTL- Con-Way Truckload -CTL Salaried and Office Staff
	 * Required: false
	 * Array: false
	 */
	benefitProgram:string = undefined;
	/**
	 * City portion of the Business address of the employee
	 * Required: false
	 * Array: false
	 */
	businessAddressCity:string = undefined;
	/**
	 * Line 1 of the Business address of the employee
	 * Required: false
	 * Array: false
	 */
	businessAddressLine1:string = undefined;
	/**
	 * Line 2 of the Business address of the employee
	 * Required: false
	 * Array: false
	 */
	businessAddressLine2:string = undefined;
	/**
	 * Line 3 of the Business address of the employee
	 * Required: false
	 * Array: false
	 */
	businessAddressLine3:string = undefined;
	/**
	 * Line 4 of the Business address of the employee
	 * Required: false
	 * Array: false
	 */
	businessAddressLine4:string = undefined;
	/**
	 * State portion of the Business address of the employee
	 * Required: false
	 * Array: false
	 */
	businessAddressStateCd:string = undefined;
	/**
	 * Postal Code portion of the Business address of the employee
	 * Required: false
	 * Array: false
	 */
	businessAddressZipCd:string = undefined;
	/**
	 * Identifies the business area that the employee is employed by
	 * Required: false
	 * Array: false
	 */
	businessUnit:string = undefined;
	/**
	 * Code identifying the company the employee is employed by
	 * Required: false
	 * Array: false
	 */
	companyCd:string = undefined;
	/**
	 * Email address assigned to the employee
	 * Required: false
	 * Array: false
	 */
	emailAddress:string = undefined;
	/**
	 * Identifies whether the person is an employee or a contractor.
	 * Valid values: 
	 * 'E' - Employee
	 * 'N' - Contractor
	 * Required: false
	 * Array: false
	 */
	employeeStatusCd:string = undefined;
	/**
	 * Employment data for an employee
	 * Required: false
	 * Array: true
	 */
	employments:Employment[] = undefined;
	/**
	 * This is a globally unique identifier assigned to
	 * all employees. They will use this for logging on to   
	 * employee self service systems from the internet.
	 * Required: false
	 * Array: false
	 */
	globalLogonId:string = undefined;
	/**
	 * Determines whether an employee is paid hourly (true) or is salaried (false).
	 * Required: false
	 * Array: false
	 */
	isHourly:boolean = undefined;
	/**
	 * Network or domain ID assigned to the employee. Could be a combination of first and last name of the
	 * employee.
	 * Required: false
	 * Array: false
	 */
	networkId:string = undefined;
	/**
	 * Phone information for the employee
	 * Required: false
	 * Array: true
	 */
	phones:Phone[] = undefined;
	/**
	 * Mainframe RACFID assigned to the employee
	 * Required: false
	 * Array: false
	 */
	racfId:string = undefined;
	/**
	 * Detail information about Roles assigned to the employee
	 * Required: false
	 * Array: true
	 */
	roles:EmployeeRole[] = undefined;
	/**
	 * Email address for the supervisor of the employee
	 * Required: false
	 * Array: false
	 */
	supervisorEmailAddress:string = undefined;
	/**
	 * Unique Identifier for the supervisor of the employee
	 * Required: false
	 * Array: false
	 */
	supervisorEmployeeId:string = undefined;
	/**
	 * Information about system access requirements for an employee
	 * Required: false
	 * Array: false
	 */
	systemAccess:EmployeeSystemAccess = undefined;
}
/**
 * Employee personal data and job position info.
 */
export class EmployeeListItem {
	/**
	 * Employee personal data info
	 * Required: false
	 * Array: false
	 */
	personalData:PersonalData = undefined;
	/**
	 * Employee job position data
	 * Required: false
	 * Array: false
	 */
	hsfJobCode:HsfJobcode = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	employeeLocation:EmployeeLocation = undefined;
}
/**
 * Role information for an employee.
 */
export class EmployeeRole {
	/**
	 * Unique Identifier for the employee
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * The date the role is effective for the employee
	 * Required: false
	 * Array: false
	 */
	startDate:Date = undefined;
	/**
	 * The date the role assignment expires for the employee
	 * Required: false
	 * Array: false
	 */
	expirationDate:Date = undefined;
	/**
	 * The service center where the role is effective for the employee
	 * Required: false
	 * Array: false
	 */
	locationSic:string = undefined;
	/**
	 * Role information
	 * Required: false
	 * Array: false
	 */
	role:Role = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Employment data for an employee
 */
export class Employment {
	/**
	 * This is the ID of the contracting firm that provided the non-employee or contractor. (Contracting Company ID)
	 * Required: false
	 * Array: false
	 */
	nonEmployeeProviderId:string = undefined;
	/**
	 * The name of the contract company providing the non-employee or contractor
	 * Required: false
	 * Array: false
	 */
	providerDescription:string = undefined;
	/**
	 * Detailed information about the job positions and the type of work that is performed for the employment record
	 * Required: false
	 * Array: true
	 */
	jobPositions:EmployeeJobPosition[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Detailed information about a job position and the type of work that is performed
 */
export class EmployeeJobPosition {
	/**
	 * Unique Identifier for the employee
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * The domicile Sic for the employee
	 * Required: false
	 * Array: false
	 */
	deptSic:string = undefined;
	/**
	 * Indicates if employee is temporary or regular.
	 * Valid values: 
	 * R = Regular 
	 * T = Temporary 
	 * (from PeopleSoft): 
	 * A = Associate
	 * C = Contractor
	 * D = Long Term Disability more than 6 mos and less than 24 mos
	 * E = Expatriate
	 * G = Retiree
	 * I = Intern 
	 * L = Long Term Disability more than 24 mos       
	 * S = Supplemental  
	 * U = Short Term Disability 
	 * V = Vendor         
	 * W = Surviving Spouse
	 * Y = Sp only Retiree Medical
	 * 3 = White Collar (INTL ONLY)                        
	 * 4 = Blue Collar (INTL ONLY)
	 * J = Temp Hire (INTL ONLY)
	 * O = Others (INTL ONLY)
	 * Required: false
	 * Array: false
	 */
	regTempCd:string = undefined;
	/**
	 * The location the employee is working out of
	 * Required: false
	 * Array: false
	 */
	locationSic:string = undefined;
	/**
	 * Description of the job position
	 * Required: false
	 * Array: false
	 */
	jobDescription:string = undefined;
	/**
	 * Identifies the type of work the employee performs
	 * Required: false
	 * Array: false
	 */
	jobCode:string = undefined;
	/**
	 * Date employee's current status became active. Could also be the  date the employee's status changed the last time
	 * Required: false
	 * Array: false
	 */
	actionDate:Date = undefined;
	/**
	 * The component for which the job code is valid
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * The action performed that last changed the employee's status (for example, Hire, Terminate, etc.)
	 * Required: false
	 * Array: false
	 */
	actionCd:string = undefined;
	/**
	 * Desc: Indicates Employee's status with the company
	 * Valid Values:
	 * A = Active   
	 * P = Leave with pay
	 * L = Leave without pay      
	 * S = Suspended 
	 * D = Deceased 
	 * R = Retired
	 * Q = Retired with pay 
	 * T = Terminated
	 * Required: false
	 * Array: false
	 */
	employeeStatus:string = undefined;
	/**
	 * The effective date of an employee's termination with the company.
	 * Required: false
	 * Array: false
	 */
	terminationDate:Date = undefined;
	/**
	 * Determines whether an employee has been terminated.
	 * Required: false
	 * Array: false
	 */
	isTerminated:boolean = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Basic information about a job role.
 */
export class RoleBasic {
	/**
	 * Unique identifier for the role
	 * Required: false
	 * Array: false
	 */
	roleId:string = undefined;
	/**
	 * Name of the role
	 * Required: false
	 * Array: false
	 */
	roleName:string = undefined;
	/**
	 * Description of the role
	 * Required: false
	 * Array: false
	 */
	roleDescription:string = undefined;
	/**
	 * The status of the role, (A)ctive, (In)active
	 * Required: false
	 * Array: false
	 */
	effectiveStatus:string = undefined;
	/**
	 * Indicates whether the role is a primary role
	 * Required: false
	 * Array: false
	 */
	primaryRoleInd:boolean = undefined;
}
/**
 * Detailed information about a job role.
 */
export class Role {
	/**
	 * Unique identifier for the role.
	 * Required: false
	 * Array: false
	 */
	roleId:string = undefined;
	/**
	 * Name of the role.
	 * Required: false
	 * Array: false
	 */
	roleName:string = undefined;
	/**
	 * Description of the role
	 * Required: false
	 * Array: false
	 */
	roleDescription:string = undefined;
	/**
	 * The date the role becomes effective
	 * Required: false
	 * Array: false
	 */
	effectiveDate:Date = undefined;
	/**
	 * The status of the role, (A)ctive, (In)active
	 * Required: false
	 * Array: false
	 */
	effectiveStatus:string = undefined;
	/**
	 * Indicates whether the role is a primary role
	 * Required: false
	 * Array: false
	 */
	primaryRoleInd:boolean = undefined;
	/**
	 * The numeric ranking of the role in the hierarchy of roles
	 * Required: false
	 * Array: false
	 */
	roleLevel:number = undefined;
	/**
	 * Code value assigned to the role
	 * Required: false
	 * Array: false
	 */
	roleCode:string = undefined;
	/**
	 * Code value indicating the category the role falls into
	 * Required: false
	 * Array: false
	 */
	categoryCode:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Identifies that the row on the table is one that is active.
	 * Required: false
	 * Array: false
	 */
	validInd:string = undefined;
}
/**
 * Detailed information about secondary job roles.
 */
export class SecondaryRoles {
	/**
	 * Code identifying the job position.
	 * Required: false
	 * Array: false
	 */
	jobCode:string = undefined;
	/**
	 * Unique identifier for the role.
	 * Required: false
	 * Array: false
	 */
	roleId:string = undefined;
	/**
	 * Name of the role.
	 * Required: false
	 * Array: false
	 */
	roleName:string = undefined;
	/**
	 * Description of the role.
	 * Required: false
	 * Array: false
	 */
	roleDescription:string = undefined;
	/**
	 * The date the role becomes effective.
	 * Required: false
	 * Array: false
	 */
	startDate:Date = undefined;
	/**
	 * The status of the role: (A)ctive or (In)active.
	 * Required: false
	 * Array: false
	 */
	effectiveStatus:string = undefined;
	/**
	 * Indicates whether the role is a primary role.
	 * Required: false
	 * Array: false
	 */
	primaryRoleInd:boolean = undefined;
	/**
	 * Code value assigned to the role.
	 * Required: false
	 * Array: false
	 */
	roleCode:string = undefined;
	/**
	 * Identifies that the row on the table is one that is active.
	 * Required: false
	 * Array: false
	 */
	validInd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Security questions and answers for an employee. This information is used whenever identity verification of the employee is required by System Admin.
 */
export class EmployeeSecurityQuestion {
	/**
	 * Unique Identifier for the employee
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * Sequence Number of the Security Question for the Employee
	 * Required: false
	 * Array: false
	 */
	seqNbr:number = undefined;
	/**
	 * Security Question selected by the Employee
	 * Required: false
	 * Array: false
	 */
	questionTxt:string = undefined;
	/**
	 * Security Answer provided by the employee's primary email address
	 * Required: false
	 * Array: false
	 */
	answerTxt:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * The date and time of a punch activity for an employee
 */
export class EmployeePunchActivity {
	/**
	 * Unique Identifier for the punch activity
	 * Required: false
	 * Array: false
	 */
	punchActivityKey:EmployeePunchActivityKey = undefined;
	/**
	 * Direction of the punch activity IN or OUT
	 * Required: false
	 * Array: false
	 */
	punchTypeCd:string = undefined;
	/**
	 * First name of the employee
	 * Required: false
	 * Array: false
	 */
	employeeFirstName:string = undefined;
	/**
	 * Last name of the employee
	 * Required: false
	 * Array: false
	 */
	employeeLastName:string = undefined;
	/**
	 * TRUE if sent, FALSE for not sent,
	 * Required: false
	 * Array: false
	 */
	sentToEobrInd:boolean = undefined;
	/**
	 * whether day light savings was applied
	 * Required: false
	 * Array: false
	 */
	dayLightSavingsInd:boolean = undefined;
	/**
	 * time zone used for this punch activity
	 * Required: false
	 * Array: false
	 */
	kronosTimeZone:string = undefined;
	/**
	 * LTL interpretation of one or more raw punch activities into a meaningful employee event.
	 * Required: false
	 * Array: false
	 */
	ltlTimeCardEventTypeCd:string = undefined;
}
/**
 * Additional Information about employee punch activities. The basic information is contained in the EmployeePunchActivityType
 */
export class EmployeePunchActivityInfo {
	/**
	 * Time that is when the database registered the punch, which takes a few minutes after the punch. Also, there could be a lag if the clock is down, network problems or if the Supervisor
	 * Required: false
	 * Array: false
	 */
	punchRecordedTime:Date = undefined;
	/**
	 * Time zone used where punch occurred.
	 * Required: false
	 * Array: false
	 */
	kronosTimeZone:string = undefined;
	/**
	 * Whether this is set for Daylight saving
	 * Required: false
	 * Array: false
	 */
	daylightSavingsInd:boolean = undefined;
	/**
	 * any comments associated with this activity
	 * Required: false
	 * Array: false
	 */
	comments:string = undefined;
	/**
	 * The hierarchical structure of labor-level entries. An example of a labor account is
	 * CWF/CWW/UCH/LEP/LEP/262/-.  This includes Company, Business Unit, Region, Location SIC, Dept Charge-to SIC and Account code. Each employee has a primary Labor Account, but the one used for the punch could be one that is transfered in for an activity other than the employee's normal work duties.
	 * Required: false
	 * Array: false
	 */
	laborAccountName:string = undefined;
	/**
	 * The identifier for the labor account.
	 * Required: false
	 * Array: false
	 */
	laborAccountId:string = undefined;
	/**
	 * Controls how time and attendance information is processed for an employee.
	 * Includes rounding, exceptions, automatic meal deductions, and overtime rules.
	 * Required: false
	 * Array: false
	 */
	workRuleName:string = undefined;
	/**
	 * Last datetime when total was applied
	 * Required: false
	 * Array: false
	 */
	lastTotalDateTime:Date = undefined;
	/**
	 * Whether total is up to date.
	 * Required: false
	 * Array: false
	 */
	totalUpToDateInd:boolean = undefined;
	/**
	 * Exception name if any such as Late
	 * Required: false
	 * Array: false
	 */
	exceptionTypeName:string = undefined;
	/**
	 * LTL interpretation of one or more raw punch activities into a meaningful employee event.
	 * Required: false
	 * Array: false
	 */
	ltlTimeCardEventTypeCd:string = undefined;
	/**
	 * Whether this record has been sent to the eobr.
	 * Required: false
	 * Array: false
	 */
	sentToEobrInd:boolean = undefined;
}
/**
 * The composite key for a punch activity
 */
export class EmployeePunchActivityKey {
	/**
	 * Unique Identifier for the employee
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * date of punch activity
	 * Required: false
	 * Array: false
	 */
	punchDate:Date = undefined;
	/**
	 * time of punch activity
	 * Required: false
	 * Array: false
	 */
	punchTime:Date = undefined;
}
/**
 * Record of trip specifics for a driver sales representative (DSR) employee
 */
export class EmployeeDsrTrip {
	/**
	 * The unique identifier for the employee
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * Id of the tractor assigned by the electronic onboard recorder. It is associated with the LTL equipment id.
	 * Required: false
	 * Array: false
	 */
	eobrTractorId:string = undefined;
	/**
	 * unique identifier of the LTL trip
	 * Required: false
	 * Array: false
	 */
	tripId:string = undefined;
	/**
	 * Trip type code - linehaul or city
	 * Required: false
	 * Array: false
	 */
	tripTypeCd:string = undefined;
	/**
	 * origin sic code
	 * Required: false
	 * Array: false
	 */
	origSic:string = undefined;
	/**
	 * desitination sic code
	 * Required: false
	 * Array: false
	 */
	destSic:string = undefined;
	/**
	 * Date and time trip was dispatched
	 * Required: false
	 * Array: false
	 */
	dispatchDateTime:Date = undefined;
	/**
	 * LoadReleaseNumber for the first trailer load being dispatched. The
	 * Load Release Number is
	 * a control number that
	 * moves with the shipment
	 * manifest for shipments on that trailer.
	 * Required: false
	 * Array: false
	 */
	loadReleaseNbr1:string = undefined;
	/**
	 * LoadReleaseNumber for the second trailer load being dispatched. The
	 * Load Release Number is
	 * a control number that
	 * moves with the shipment
	 * manifest for shipments on that trailer.
	 * Required: false
	 * Array: false
	 */
	loadReleaseNbr2:string = undefined;
	/**
	 * LoadReleaseNumber for the third trailer load being dispatched. The
	 * Load Release Number is
	 * a control number that
	 * moves with the shipment
	 * manifest for shipments on that trailer.
	 * Required: false
	 * Array: false
	 */
	loadReleaseNbr3:string = undefined;
	/**
	 * Trailer number prefix for first trailer being dispatched.
	 * Required: false
	 * Array: false
	 */
	equipmentPrefix1:string = undefined;
	/**
	 * Trailer number suffix for first trailer being dispatched.
	 * Required: false
	 * Array: false
	 */
	equipmentSuffix1:string = undefined;
	/**
	 * Trailer number prefix for second trailer being dispatched.
	 * Required: false
	 * Array: false
	 */
	equipmentPrefix2:string = undefined;
	/**
	 * Trailer number suffix for second trailer being dispatched.
	 * Required: false
	 * Array: false
	 */
	equipmentSuffix2:string = undefined;
	/**
	 * Trailer number prefix for thirdtrailer being dispatched.
	 * Required: false
	 * Array: false
	 */
	equipmentPrefix3:string = undefined;
	/**
	 * Trailer number suffix for thirdmtrailer being dispatched.
	 * Required: false
	 * Array: false
	 */
	equipmentSuffix3:string = undefined;
	/**
	 * any comments associated with this dispatch
	 * Required: false
	 * Array: false
	 */
	comments:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	sentToEobrInd:boolean = undefined;
}
/**
 * Basic information about a job code.
 */
export class JobCodeBasic {
	/**
	 * Code identifying the job position.
	 * Required: false
	 * Array: false
	 */
	jobCode:string = undefined;
	/**
	 * Describes the job position.
	 * Required: false
	 * Array: false
	 */
	jobDescription:string = undefined;
	/**
	 * The date the job position becomes effective.
	 * Required: false
	 * Array: false
	 */
	jobEffectiveDate:Date = undefined;
}
/**
 * Identifies the type of work the employee performs.
 */
export class JobCode {
	/**
	 * Code identifying the job position.
	 * Required: false
	 * Array: false
	 */
	jobCode:string = undefined;
	/**
	 * Describes the job position.
	 * Required: false
	 * Array: false
	 */
	jobDescription:string = undefined;
	/**
	 * The date the job position becomes effective.
	 * Required: false
	 * Array: false
	 */
	jobEffectiveDate:Date = undefined;
	/**
	 * Level of Manager for the job.
	 * Required: false
	 * Array: false
	 */
	managerRoleLevel:string = undefined;
	/**
	 * Description of Manager Level.
	 * Required: false
	 * Array: false
	 */
	managerRoleDescription:string = undefined;
	/**
	 * The numeric ranking of the role in the hierarchy of roles
	 * Required: false
	 * Array: false
	 */
	roleLevel:number = undefined;
	/**
	 * Function code for the job position.
	 * Required: false
	 * Array: false
	 */
	functionCode:string = undefined;
	/**
	 * Unique identifier for the role
	 * Required: false
	 * Array: false
	 */
	role:Role = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Identifies that the row on the table is one that is active.
	 * Required: false
	 * Array: false
	 */
	validInd:string = undefined;
}
/**
 * Used to assign secondary roles to a job code.
 */
export class SecondaryRolesToJobCode {
	/**
	 * Unique identifier for the job code.
	 * Required: true
	 * Array: false
	 */
	jobCode:string = undefined;
	/**
	 * Unique identifier for the role.
	 * Required: false
	 * Array: false
	 */
	roleId:string = undefined;
	/**
	 * The timestamp for when the assignment expired.
	 * Required: false
	 * Array: false
	 */
	expiredTimestamp:Date = undefined;
	/**
	 * If true, then this operation will save the information. ï¿½If false, it will remove it.
	 * Required: false
	 * Array: false
	 */
	saveInd:boolean = undefined;
	/**
	 * Standard audit trail information.
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Information about the Employee and their Job and Roles
 */
export class EmployeeJobRole {
	/**
	 * The domicile Sic for the employee
	 * Required: false
	 * Array: false
	 */
	deptSic:string = undefined;
	/**
	 * The district code where the employee belongs to
	 * Required: false
	 * Array: false
	 */
	district:string = undefined;
	/**
	 * Basic Information about the Employee
	 * Required: false
	 * Array: false
	 */
	employee:EmployeeIdBasic = undefined;
	/**
	 * Determines whether an employee has been terminated.
	 * Required: false
	 * Array: false
	 */
	isTerminated:boolean = undefined;
	/**
	 * Employee Job Information
	 * Required: false
	 * Array: false
	 */
	job:JobCodeBasic = undefined;
	/**
	 * Basic Information about the Employee Manager
	 * Required: false
	 * Array: false
	 */
	manager:EmployeeIdBasic = undefined;
	/**
	 * The region code where the employee belongs to
	 * Required: false
	 * Array: false
	 */
	region:string = undefined;
	/**
	 * Basic Information about the person the employee reports to
	 * Required: false
	 * Array: false
	 */
	reportsTo:EmployeeIdBasic = undefined;
	/**
	 * Employee Role information
	 * Required: false
	 * Array: true
	 */
	role:RoleBasic[] = undefined;
	/**
	 * The effective date of an employee's termination with the company.
	 * Required: false
	 * Array: false
	 */
	terminationDate:Date = undefined;
}
/**
 * List of employee name information.
 */
export class EmployeeName {
	/**
	 * Unique identifier for the employee record.
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * The full name of the employee.
	 * Required: false
	 * Array: false
	 */
	fullName:string = undefined;
	/**
	 * The first name of the employee.
	 * Required: false
	 * Array: false
	 */
	firstName:string = undefined;
	/**
	 * The last name of the employee.
	 * Required: false
	 * Array: false
	 */
	lastName:string = undefined;
	/**
	 * The middle name (often initial) of the employee.
	 * Required: false
	 * Array: false
	 */
	middleName:string = undefined;
	/**
	 * The preferred first name of the employee.
	 * Required: false
	 * Array: false
	 */
	preferredFirstName:string = undefined;
	/**
	 * The name prefix of the employee.
	 * Required: false
	 * Array: false
	 */
	namePrefix:string = undefined;
	/**
	 * The name suffix of the employee.
	 * Required: false
	 * Array: false
	 */
	nameSuffix:string = undefined;
	/**
	 * The Success Factors ID of the employee.
	 * Required: false
	 * Array: false
	 */
	sfEmplId:string = undefined;
}
/**
 * Information about an employee and their domicile.
 */
export class EmployeeDomicile {
	/**
	 * The unique identifier for the employee record.
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * The domicile SIC of the employee
	 * Required: false
	 * Array: false
	 */
	domicileSic:string = undefined;
	/**
	 * Identifies if the employee is a driver.
	 * Required: false
	 * Array: false
	 */
	isDriverInd:boolean = undefined;
}
/**
 * Information about an employee and their domicile.
 */
export class ElogEmployeeJobRole {
	/**
	 * The host SIC for the employee.
	 * Required: false
	 * Array: false
	 */
	hostSic:string = undefined;
	/**
	 * The department SIC for the employee.
	 * Required: false
	 * Array: false
	 */
	deptSic:string = undefined;
	/**
	 * Determines whether an employee has been terminated.
	 * Required: false
	 * Array: false
	 */
	isTerminated:boolean = undefined;
	/**
	 * Determines whether the employee is ELD exempt.
	 * Required: false
	 * Array: false
	 */
	isEldExempt:boolean = undefined;
	/**
	 * Details about an employee including their name and ID.
	 * Required: false
	 * Array: false
	 */
	employee:EmployeeName = undefined;
	/**
	 * Job code information.
	 * Required: false
	 * Array: false
	 */
	jobCode:JobCodeBasic = undefined;
	/**
	 * Drivers license information for employee.
	 * Required: false
	 * Array: false
	 */
	driversLicense:DriversLic = undefined;
}
/**
 * 
 */
export class BusinessException {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventRecord:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	status:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	message:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	eventTypeNm:string = undefined;
}
/**
 * 
 */
export class DriversLic {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	licNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	validFromDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	state:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	country:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	exprDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	licenseType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	comments:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class EmployeeEmailAddress {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emailType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emailAddress:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	preferredEmailInd:boolean = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Email UPN.
	 * Required: false
	 * Array: false
	 */
	upn:string = undefined;
}
/**
 * 
 */
export class EmployeeIdXref {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sfEmplId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	psEmplId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class EmployeePhone {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	typeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	phoneCountryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	phone:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Job {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emplRcd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effSequence:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	companyCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	businessUnit:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dept:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	setidDept:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fullPartCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	regTempCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	location:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emplStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actnCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actionDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	actnReasonCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	positionNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportsTo:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hireDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastHireDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	terminationDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	setidLocation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hrStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	district:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	region:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sfCostCenter:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sfDeptId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sfLocation:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sfCompany:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	driverTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	paygroup:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportsToEmplid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emplType:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether the row is an active valid row.
	 * Required: false
	 * Array: false
	 */
	validInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	businessUnitName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	costCenterName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	districtName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	regionName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sfCompanyName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sfDeptName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastWorkDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportsToFirstName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportsToLastName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportsToEmailAddress:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hsfJobcode:HsfJobcode = undefined;
}
/**
 * 
 */
export class HsfJobcode {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	setid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	jobcode:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	jobEffectiveDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cmptCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	jobDescription:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	roleId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	managerLevelCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	managerLevelDescription:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	functionCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether the row is an active valid row.
	 * Required: false
	 * Array: false
	 */
	validInd:boolean = undefined;
}
/**
 * 
 */
export class EmployeeLocation {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	setid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	locationCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	locationSicCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	statusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address1:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address2:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address3:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	address4:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cityNm:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	stateCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	countryCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	zip:string = undefined;
	/**
	 * The Success Factors (SF) location name.
	 * Required: false
	 * Array: false
	 */
	sfLocationName:string = undefined;
	/**
	 * A string description representing the name of a SIC.  For example, UPO would be ï¿½PORTLANDï¿½.
	 * Required: false
	 * Array: false
	 */
	locationSicName:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class NeeProvider {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	providerId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	providerDescr:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class PersonalData {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fullName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	firstName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	middleName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	preferredFirstName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	namePrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nameSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	firstNameSrch:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	lastNameSrch:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	alterEmplid:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sfEmplid:string = undefined;
}
/**
 * 
 */
export class PersonNid {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	country:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	idType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	nationalId:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class PersonOrgAssignment {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emplRcd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	personOrg:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	poiType:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	serviceDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	cmpnySeniorityDate:Date = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class PersonOrgInst {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	orgInstanceErn:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	neeProviderId:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Position {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	positionNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	descr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dept:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reportsTo:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dttedLineRptsTo:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sfCompany:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sfCostCenter:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sfDeptId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hsfJobcode:HsfJobcode = undefined;
}
/**
 * 
 */
export class PreferredName {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	preferredFirstName:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class IdmEmployeeRole {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	role:IdmRole = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	locationSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	startDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	expirationDate:Date = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 * Required: false
	 * Array: true
	 */
	idmRole:IdmRole[] = undefined;
}
/**
 * 
 */
export class IdmEventLog {
	/**
	 * This is 11 character unique ID assigned to a person when he joins the company. Currently we use only 5 characters. The rest of the characters are spaces.
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * Identifies the type of event happening for an employee.
	 * Valid Values:
	 * 1 System Access change
	 * 2 Racf Id change
	 * 3 Role Change
	 * Required: false
	 * Array: false
	 */
	eventTypeCd:string = undefined;
	/**
	 * Timestamp of the event.
	 * Required: false
	 * Array: false
	 */
	eventCreateTimestamp:Date = undefined;
	/**
	 * Concatenated, comma separated string of previous set of System Access indicator values for this employee.
	 * Required: false
	 * Array: false
	 */
	oldValue:string = undefined;
	/**
	 * Concatenated, comma separated string of new set of System Access indicator values for this employee
	 * Required: false
	 * Array: false
	 */
	newValue:string = undefined;
	/**
	 * When there is a change to an employee?s roles (adding new role, expiring existing role), this is the Role Id that is being added or expired
	 * Required: false
	 * Array: false
	 */
	roleId:string = undefined;
	/**
	 * The date when the new role is effective, or the existing start date for the role that is being expired.
	 * Required: false
	 * Array: false
	 */
	roleEffDate:Date = undefined;
	/**
	 * The date when the role will no longer be effective
	 * Required: false
	 * Array: false
	 */
	roleExprDate:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class IdmRole {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	roleId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effDate:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	roleName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	roleCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	catgCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	descr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	roleLevel:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	priRoleInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates whether the row is a valid active row.
	 * Required: false
	 * Array: false
	 */
	validInd:boolean = undefined;
}
/**
 * 
 */
export class EmployeeSystemAccess {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	racfInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	winLogonInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	emailInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	vpnInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	internetInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tsoInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sarInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	tm1Ind:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	navitusInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	oracleFinInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	keteraInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	biInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	comments:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class EmployeeSystemId {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	racfId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	effectiveStatus:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	remoteAccessId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	winaltId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	networkUid:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	domain:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	oracleFinId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	remoteAccessToken:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	globalLogonId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	superUserInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	comments:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}

/**
 * Query parameters for listRoles
 */
export class ListRolesQuery {
	roleId: number = undefined;
	roleName: string = undefined;
	roleDescription: string = undefined;
	effectiveDate: string = undefined;
	primaryRoleInd: string = undefined;
	roleLevel: number = undefined;
	roleCode: string = undefined;
	categoryCode: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listSecondaryRolesByJobCode
 */
export class ListSecondaryRolesByJobCodeQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for getFullEmployeeDetailsByEmpId
 */
export class GetFullEmployeeDetailsByEmpIdQuery {
	requesterId: string = undefined;
	effectiveDate: string = undefined;
}

/**
 * Query parameters for listEmployeesByName
 */
export class ListEmployeesByNameQuery {
	firstName: string = undefined;
	lastName: string = undefined;
	sicCd: string = undefined;
	roleCd: string[] = undefined;
}

/**
 * Query parameters for getDomicileSicForEmployee
 */
export class GetDomicileSicForEmployeeQuery {
	effectiveDate: string = undefined;
}

/**
 * Query parameters for listEmployeesBySic
 */
export class ListEmployeesBySicQuery {
	searchString: string = undefined;
	roleCd: string[] = undefined;
}

/**
 * Query parameters for getEmployeeDetailsByEmpId
 */
export class GetEmployeeDetailsByEmpIdQuery {
	effectiveDate: string = undefined;
	requestType: string = undefined;
}

/**
 * Query parameters for getHrsEmployeeDetailsByEmpId
 */
export class GetHrsEmployeeDetailsByEmpIdQuery {
	employeeIdType: EmployeeIdTypeCd = undefined;
}

/**
 * Query parameters for getEmployeeJobInfoForDriver
 */
export class GetEmployeeJobInfoForDriverQuery {
	effectiveDate: string = undefined;
}

/**
 * Query parameters for listDsrEmployees
 */
export class ListDsrEmployeesQuery {
	locationSic: string = undefined;
	startingDsrLastName: string = undefined;
}

/**
 * Query parameters for listDirectReportEmployees
 */
export class ListDirectReportEmployeesQuery {
	supervisorId: string = undefined;
	employeeType: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listEmailAddressesForEmployee
 */
export class ListEmailAddressesForEmployeeQuery {
	preferredEmailAddressInd: string = undefined;
}

/**
 * Query parameters for getSystemIdDetailsByDomainAndUserId
 */
export class GetSystemIdDetailsByDomainAndUserIdQuery {
	domain: string = undefined;
	userId: string = undefined;
}

/**
 * Query parameters for listLunchPunchesSinceDateTime
 */
export class ListLunchPunchesSinceDateTimeQuery {
	requestDate: string = undefined;
	requestTime: string = undefined;
	sentToEobrInd: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listEmployeePunchesSinceDateTime
 */
export class ListEmployeePunchesSinceDateTimeQuery {
	requestDate: string = undefined;
	requestTime: string = undefined;
	sentToEobrInd: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listDsrTripsSinceDateTime
 */
export class ListDsrTripsSinceDateTimeQuery {
	dispatchDateTime: string = undefined;
	sentToEobrInd: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listJobCodeRoleAssignments
 */
export class ListJobCodeRoleAssignmentsQuery {
	jobCode: string = undefined;
	jobDescription: string = undefined;
}

/**
 * Query parameters for listEmployeesByJobCode
 */
export class ListEmployeesByJobCodeQuery {
	jobCode: string[] = undefined;
	beginningDate: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listElogEmployeesByJobCode
 */
export class ListElogEmployeesByJobCodeQuery {
	jobCode: string[] = undefined;
	beginningDate: string = undefined;
}

/**
 * Query parameters for getEmployeeIdXref
 */
export class GetEmployeeIdXrefQuery {
	peopleSoftEmployeeId: string = undefined;
	successFactorsEmployeeId: string = undefined;
}


/**
 * Path parameters for listRolesForEmployee
 */
export class ListRolesForEmployeePath {
	employeeId: string = undefined;
}

/**
 * Path parameters for getEmployeeCountByRole
 */
export class GetEmployeeCountByRolePath {
	role: string = undefined;
}

/**
 * Path parameters for listSecondaryRolesByJobCode
 */
export class ListSecondaryRolesByJobCodePath {
	jobCode: string = undefined;
}

/**
 * Path parameters for listEmployeesByRoleId
 */
export class ListEmployeesByRoleIdPath {
	roleId: string = undefined;
}

/**
 * Path parameters for getFullEmployeeDetailsByEmpId
 */
export class GetFullEmployeeDetailsByEmpIdPath {
	employeeId: string = undefined;
}

/**
 * Path parameters for determineContractorEmployeeConversion
 */
export class DetermineContractorEmployeeConversionPath {
	sfEmplId: string = undefined;
}

/**
 * Path parameters for searchEmployeeDetailsBySic
 */
export class SearchEmployeeDetailsBySicPath {
	locationSic: string = undefined;
	searchString: string = undefined;
	includeZones: string = undefined;
}

/**
 * Path parameters for getDomicileSicForEmployee
 */
export class GetDomicileSicForEmployeePath {
	employeeId: string = undefined;
}

/**
 * Path parameters for listEmployeesBySic
 */
export class ListEmployeesBySicPath {
	sicCd: string = undefined;
}

/**
 * Path parameters for getEmployeeDetailsByEmpId
 */
export class GetEmployeeDetailsByEmpIdPath {
	employeeId: string = undefined;
}

/**
 * Path parameters for getHrsEmployeeDetailsByEmpId
 */
export class GetHrsEmployeeDetailsByEmpIdPath {
	employeeId: string = undefined;
}

/**
 * Path parameters for getEmployeeJobInfoForDriver
 */
export class GetEmployeeJobInfoForDriverPath {
	employeeId: string = undefined;
}

/**
 * Path parameters for listDirectReportEmployees
 */
export class ListDirectReportEmployeesPath {
	employeeId: string = undefined;
}

/**
 * Path parameters for listEmailAddressesForEmployee
 */
export class ListEmailAddressesForEmployeePath {
	employeeId: string = undefined;
}

/**
 * Path parameters for listSecurityQuestionsForEmployee
 */
export class ListSecurityQuestionsForEmployeePath {
	employeeId: string = undefined;
}

/**
 * Path parameters for isEmployeeADriver
 */
export class IsEmployeeADriverPath {
	employeeId: string = undefined;
}

/**
 * Path parameters for getSystemAccessForEmployee
 */
export class GetSystemAccessForEmployeePath {
	employeeId: string = undefined;
}

/**
 * Path parameters for getSystemIdDetailsForEmployee
 */
export class GetSystemIdDetailsForEmployeePath {
	employeeId: string = undefined;
}

/**
 * Path parameters for getLatestActivityForEmployee
 */
export class GetLatestActivityForEmployeePath {
	employeeId: string = undefined;
}

/**
 * Path parameters for getEmployeeCountByJobCode
 */
export class GetEmployeeCountByJobCodePath {
	jobCode: string = undefined;
}


