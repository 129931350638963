import {ApiContractExceptionSeverityCd} from '../';
import {EnumHelper} from './enum-helper';

export class ApiContractExceptionSeverityCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ApiContractExceptionSeverityCd ) {
      this._values.push(ApiContractExceptionSeverityCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ApiContractExceptionSeverityCd {

    for ( const obj in ApiContractExceptionSeverityCd ) {
      if (ApiContractExceptionSeverityCd[obj] === value){
        return ApiContractExceptionSeverityCd[obj] as ApiContractExceptionSeverityCd;
      }
    }
  }
}

const ApiContractExceptionSeverityCdHelper = new ApiContractExceptionSeverityCdHelperEnumHelperClass();
export default ApiContractExceptionSeverityCdHelper;
