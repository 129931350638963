/**
 * Logging API
 * Version: 1.0
 * Build: Manual
 */

import {
	LogLevel
} from '@xpo-ltl/sdk-common';

/**
 * Request to log.
 */
export class InsertLogRqst {
	/**
	 * Details of what to log
	 */
	Log:Log = undefined;
}
/**
 * Log record layout
 */
export class Log {
	hdr:Header = undefined;
	bdy:Body = undefined;
}
export class AnyType {
	/**
	 * Any data type. Use domain specific pre-defined types
	 */
	data:object = undefined;
}
export class Body {
	/**
	 * Detailed contextual information for the logevent. eg: employee info, order detail info
	 */
	detailedContext:string = undefined;
	/**
	 * Map to technology specific process info ex:BWProcessInfoType
	 */
	processInfo:AnyType = undefined;
	/**
	 * Map the technology specific error report ex:BWErrorReportType
	 */
	errorRpt:AnyType = undefined;
}
export class Header {
	/**
	 * This is the current timestamp that this logmessage is being created.
	 */
	logTimestamp:Date = undefined;
	logLevel:LogLevel = undefined;
	/**
	 * This is the name of the orgainization e.g. XPO.
	 */
	org:string = undefined;
	/**
	 * This is the name of the division within the orge.g. LTL
	 */
	div:string = undefined;
	/**
	 * This is the envrionment within that division e.g.CTSI, CTS2, CTSS, PROD
	 */
	env:string = undefined;
	/**
	 * This is the layer within that env e.g. BW, BE,API, WAS
	 */
	layer:string = undefined;
	/**
	 * This is the name of the application that islogging
	 */
	appName:string = undefined;
	/**
	 * This is the name of the application instance thatis logging
	 */
	appInstanceName:string = undefined;
	/**
	 * This is the projectName from which this messageis being logged. This can be mapped from the projectName elementunder the $_processContext variable.
	 */
	projectName:string = undefined;
	/**
	 * specifies the core group the application belongsto e.g. S2C.OMS, P2D.SCO, S2C.SHM, B2B.EDI
	 */
	appGroupName:string = undefined;
	/**
	 * specifies the business function that this logrecord is related to e.g. Billing, Corrections, Invociing
	 */
	busFuncName:string = undefined;
	/**
	 * hostname of the logging application
	 */
	hostName:string = undefined;
	/**
	 * a unique id published by an app that associates abusiness transaction to a message or set of messages e.g shipmentpickup
	 */
	correlationId:string = undefined;
	processName:string = undefined;
	processId:string = undefined;
	threadName:string = undefined;
	sessionName:string = undefined;
	lockKey:string = undefined;
	activityName:string = undefined;
	/**
	 * short contextual information for this log event.e.g Order not billed in OMS
	 */
	shortContext:string = undefined;
	tranType:string = undefined;
	action:string = undefined;
	eventType:string = undefined;
	/**
	 * Optional return code
	 */
	returnCode:string = undefined;
	/**
	 * Optional reason code.
	 */
	reasonCode:string = undefined;
	/**
	 * Indicates whether this log event is a result ofretry operation. If not true, exception will be generated toclient - Used by BW clients
	 */
	isRetry:boolean = undefined;
	/**
	 * Indicates whether domain attributes i.e. key datais stored in job sharedvariable. If true logger utility will mapfields from key data jobshared variable - Used by BW clients
	 */
	isDomainAttrbsInJobShrVar:boolean = undefined;
	/**
	 * Domain speciific search attrivutes ex:ShipmentSearchType
	 */
	domainAttrbs:AnyType = undefined;
	/**
	 * Use domain specific json string. Use this onlyafter exhausing the possiblities with domainAttrbs node
	 */
	domainJson:string = undefined;
	/**
	 * Map technoilogy search attributes ex:BWAttributes
	 */
	appTechAttrbs:AnyType = undefined;
	/**
	 * Kafka configuration
	 */
	kafkaConfig:KafkaLogConfig = undefined;
}
export class KafkaLogConfig {
	/**
	 * Kafka Topic Name to log this message
	 */
	logTopicName:string = undefined;
	/**
	 * If true creates new Kafka Config Instance,otherwise, reuses the existing config instance. Default is false.
	 */
	isNewConfig:boolean = undefined;
	logBootStrapServers:string = undefined;
	logAckMode:string = undefined;
	lingerTimeMillisec:string = undefined;
	logBufferMemory:string = undefined;
	logRetries:number = undefined;
	logBatchSize:number = undefined;
}

