
export enum TripNodeActivityCd {
	ARRIVE = 'Arrive',
	DELIVER_SHIPMENT = 'DeliverShipment',
	DEPART_DISPATCH = 'Depart/Dispatch',
	DROP_EQUIPMENT = 'DropEquipment',
	HOOK_EMPTY = 'HookEmpty',
	HOOK_EQUIPMENT = 'HookEquipment',
	HOOK_LOADED = 'HookLoaded',
	PICKUP_SHIPMENTS = 'PickupShipments',
	SPOT_EMPTY = 'SpotEmpty',
	SPOT_LOADED = 'SpotLoaded'}

