
export enum NoteTypeCd {
	OVERRIDE_DELIVERY_INSTRUCTIONS = 'OverrideDeliveryInstructions',
	OVERRIDE_INSTRUCTIONS = 'OverrideInstructions',
	OVERRIDE_OPERATIONS = 'OverrideOperations',
	OVERRIDE_PICKUP_INSTRUCTIONS = 'OverridePickupInstructions',
	ROUTE = 'Route',
	STOP = 'Stop',
	TRIP = 'Trip'}

