
export enum BillClassCd {
	ACCESSORIAL_ONLY = 'AccessorialOnly',
	ASTRAY_FRT_SEGMENT = 'AstrayFrtSegment',
	CLAIMS_OVRG_RPTG_BILL = 'ClaimsOvrgRptgBill',
	CO_BUS_SHPMT = 'CoBusShpmt',
	EXPEDITE = 'Expedite',
	GENERAL_CLAIMS_BUS_SHPMT = 'GeneralClaimsBusShpmt',
	MASTER_SEGMENT = 'MasterSegment',
	MASTER_SHPMT = 'MasterShpmt',
	NORMAL_MVMT = 'NormalMvmt',
	PARTIAL_SEGMENT = 'PartialSegment',
	SPLIT_SEGMENT = 'SplitSegment'}

