import {ShipmentManagementRemarkTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ShipmentManagementRemarkTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ShipmentManagementRemarkTypeCd ) {
      this._values.push(ShipmentManagementRemarkTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ShipmentManagementRemarkTypeCd {

    for ( const obj in ShipmentManagementRemarkTypeCd ) {
      if (ShipmentManagementRemarkTypeCd[obj] === value){
        return ShipmentManagementRemarkTypeCd[obj] as ShipmentManagementRemarkTypeCd;
      }
    }
  }
}

const ShipmentManagementRemarkTypeCdHelper = new ShipmentManagementRemarkTypeCdHelperEnumHelperClass();
export default ShipmentManagementRemarkTypeCdHelper;
