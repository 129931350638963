import {ProRequestStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class ProRequestStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ProRequestStatusCd ) {
      this._values.push(ProRequestStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ProRequestStatusCd {

    for ( const obj in ProRequestStatusCd ) {
      if (ProRequestStatusCd[obj] === value){
        return ProRequestStatusCd[obj] as ProRequestStatusCd;
      }
    }
  }
}

const ProRequestStatusCdHelper = new ProRequestStatusCdHelperEnumHelperClass();
export default ProRequestStatusCdHelper;
