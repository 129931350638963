
export enum ShipmentSpecialServiceCd {
	APPOINTMENT = 'Appointment',
	BYPASS = 'Bypass',
	CASH_ON_DELIVERY = 'CashOnDelivery',
	DRIVER_COLLECT = 'DriverCollect',
	EXPEDITE = 'Expedite',
	FOOD = 'Food',
	FORK_LIFT = 'ForkLift',
	FREEZABLE = 'Freezable',
	GUARANTEED = 'Guaranteed',
	GUARANTEED_BY_NOON = 'GuaranteedByNoon',
	HAZMAT = 'Hazmat',
	HEADLOAD = 'Headload',
	HIGHWAY_SUB_SERVICE = 'HighwaySubService',
	LIFT_GATE = 'LiftGate',
	LINEHAUL = 'Linehaul',
	MISLOAD = 'Misload',
	NOTIFY_ON_ARRIVAL = 'NotifyOnArrival',
	PALLET_JACK = 'PalletJack',
	PARTIAL_BYPASS = 'PartialBypass',
	PUP_ONLY = 'PupOnly',
	RAPID_REMOTE_SERVICE = 'RapidRemoteService',
	SUPPLEMENTAL = 'Supplemental',
	TIME_DATE_CRITICAL = 'TimeDateCritical',
	UNMAPPED = 'Unmapped'}

