/**
 * Aem API
 * Version: 1.0
 * Build: 1.0-1.0.0.6
 */

import {
	ListInfo,
	AuditInfo,
	ActionCd
} from '@xpo-ltl/sdk-common';

/**
 * Request to create a component for application exception messages.
 */
export class CreateAemComponentRqst {
	/**
	 * The code that represents the component.
	 * Required: true
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * A description of the component.
	 * Required: true
	 * Array: false
	 */
	componentDescription:string = undefined;
}
/**
 * Response to the request to create a component for application exception messages.
 */
export class CreateAemComponentResp {
	/**
	 * The new component that was created for application exception messages.
	 * Required: false
	 * Array: false
	 */
	component:Component = undefined;
}
/**
 * Request to create a component with error messages for application exception messages.
 */
export class CreateAemComponentWithMessagesRqst {
	/**
	 * The new component that will be created for application exception messages.
	 * Required: true
	 * Array: false
	 */
	component:Component = undefined;
	/**
	 * A list of error messages.
	 * Required: true
	 * Array: true
	 */
	errorMessages:ErrorMessage[] = undefined;
}
/**
 * Response to the request to create a component with error messages for application exception messages.
 */
export class CreateAemComponentWithMessagesResp {
	/**
	 * The new component that was created for application exception messages.
	 * Required: false
	 * Array: false
	 */
	component:Component = undefined;
	/**
	 * A list of error messages.
	 * Required: false
	 * Array: true
	 */
	errorMessages:ErrorMessage[] = undefined;
}
/**
 * Path parameter used to delete a component for application exception messages.
 */
export class DeleteAemComponentPath {
	/**
	 * The code that represents the component.
	 * Required: true
	 * Array: false
	 */
	componentCd:string = undefined;
}
/**
 * Query parameter used to list all components along with their descriptions and origin server IDs.
 */
export class ListAemComponentsQuery {
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list all components along with their descriptions and origin server IDs.
 */
export class ListAemComponentsResp {
	/**
	 * The application exception message components.
	 * Required: false
	 * Array: true
	 */
	components:Component[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to update the description for a component.
 */
export class UpdateAemComponentRqst {
	/**
	 * The code that represents the component.
	 * Required: true
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * The description for the component.
	 * Required: true
	 * Array: false
	 */
	componentDescription:string = undefined;
}
/**
 * Rresponse to the request to update the description for a component.
 */
export class UpdateAemComponentResp {
	/**
	 * The new component that was updated for application exception messages.
	 * Required: false
	 * Array: false
	 */
	component:Component = undefined;
}
/**
 * Path parameter used to create an error message for a component.
 */
export class CreateAemErrorMessagePath {
	/**
	 * The code that represents the component.
	 * Required: true
	 * Array: false
	 */
	componentCd:string = undefined;
}
/**
 * Request to create an error message for a component.
 */
export class CreateAemErrorMessageRqst {
	/**
	 * The error message to be added to a component.
	 * Required: true
	 * Array: false
	 */
	errorMessage:ErrorMessage = undefined;
}
/**
 * Response to the request to create an error message for a component.
 */
export class CreateAemErrorMessageResp {
	/**
	 * The new error message that was created for application exception messages.
	 * Required: false
	 * Array: false
	 */
	errorMessage:ErrorMessage = undefined;
}
/**
 * Path parameters used to delete an error message for a component.
 */
export class DeleteAemErrorMessagePath {
	/**
	 * The component associated with the error message.
	 * Required: true
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * The return code associated with the error message.
	 * Required: true
	 * Array: false
	 */
	returnCd:string = undefined;
	/**
	 * The reason code associated with the error message.
	 * Required: true
	 * Array: false
	 */
	reasonCd:string = undefined;
}
/**
 * Path parameter used to list all error messages by component.
 */
export class ListAemErrorMessagesPath {
	/**
	 * The component associated with the error message.
	 * Required: true
	 * Array: false
	 */
	componentCd:string = undefined;
}
/**
 * Query parameters uesd to list all error messages by component.
 */
export class ListAemErrorMessagesQuery {
	/**
	 * The return code associated with the error message.
	 * Required: false
	 * Array: false
	 */
	returnCd:string = undefined;
	/**
	 * The reason code associated with the error message.
	 * Required: false
	 * Array: false
	 */
	reasonCd:string = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list all components along with their descriptions and origin server IDs.
 */
export class ListAemErrorMessagesResp {
	/**
	 * The application exception message error messages.
	 * Required: false
	 * Array: true
	 */
	errorMessages:ErrorMessage[] = undefined;
	/**
	 * The component associated with the error messages.
	 * Required: false
	 * Array: false
	 */
	component:Component = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Path parameter used to update the name and message text for an error message.
 */
export class UpdateAemErrorMessagePath {
	/**
	 * The code for the component that is to be updated.
	 * Required: true
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * The return code associated with the error message.
	 * Required: true
	 * Array: false
	 */
	returnCd:string = undefined;
	/**
	 * The reason code associated with the error message.
	 * Required: true
	 * Array: false
	 */
	reasonCd:string = undefined;
}
/**
 * Request to update the name and message text for an error message.
 */
export class UpdateAemErrorMessageRqst {
	/**
	 * The error message to be updated for a component.
	 * Required: true
	 * Array: false
	 */
	errorMessage:ErrorMessage = undefined;
}
/**
 * Response to the request to update the name and message text for an error message.
 */
export class UpdateAemErrorMessageResp {
	/**
	 * The new error message that was updated for application exception messages.
	 * Required: false
	 * Array: false
	 */
	errorMessage:ErrorMessage = undefined;
}
/**
 * Request to backsync the AEM component and error message to the legacy tables.
 */
export class BacksyncAemErrorMessageRqst {
	/**
	 * The Pub message record to backsync to the legacy tables.
	 * Required: true
	 * Array: false
	 */
	pubAemErrorMessageRecord:PubAemErrorMessageRecord = undefined;
}
/**
 * 
 */
export class PubAemErrorMessageRecord {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentDescription:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	legacyOriginServerId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	returnCd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reasonCd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	errorMessage:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	legacyMessageName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	legacyMessageId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	legacySeverityCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pubSeqNbr:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pubDlvyStatCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pubActionCd:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pubCrteTmst:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pubLstUpdtTmst:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	pubTypeCd:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions. 
	 * In other words, this groups multipple rows across various tables for a single business transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Component {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentDescription:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	legacyOriginServerId:number = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ErrorMessage {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	returnCd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reasonCd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	errorMessage:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	legacyMessageName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	legacyMessageId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	legacySeverityCd:string = undefined;
	/**
	 * Same value for CORRELATION id is assigned to various rows from different tables that form a single business transactions.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ExhMessage {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	messageId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	logFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	messageNameTx:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	severityCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dialectCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicateFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicaDistanceCd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	messageTx:string = undefined;
}
/**
 * 
 */
export class ExhMsgTx {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	messageId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dialectCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	messageTx:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	dnRob12ReplicationCd:number = undefined;
}
/**
 * 
 */
export class Implementation {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	implementationId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicaDistanceCd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	impStringTx:string = undefined;
}
/**
 * 
 */
export class ImpMsgMap {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	implementationId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	returnCd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reasonCd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	messageId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	autoAddedFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicaDistanceCd:number = undefined;
}
/**
 * 
 */
export class OriginServer {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originServerId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicaDistanceCd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originServTx:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	implementationId:number = undefined;
}
/**
 * 
 */
export class OriginMsgMap {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	originServerId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	returnCd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reasonCd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	messageId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	autoAddedFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicaDistanceCd:number = undefined;
}
/**
 * 
 */
export class Specification {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	specId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicaDistanceCd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	specStringTx:string = undefined;
}
/**
 * 
 */
export class SpecMsgMap {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	specId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	returnCd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	reasonCd:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	messageId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	autoAddedFlag:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	componentCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	replicaDistanceCd:number = undefined;
}



