import {ProPadTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class ProPadTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ProPadTypeCd ) {
      this._values.push(ProPadTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ProPadTypeCd {

    for ( const obj in ProPadTypeCd ) {
      if (ProPadTypeCd[obj] === value){
        return ProPadTypeCd[obj] as ProPadTypeCd;
      }
    }
  }
}

const ProPadTypeCdHelper = new ProPadTypeCdHelperEnumHelperClass();
export default ProPadTypeCdHelper;
