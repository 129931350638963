import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	BacksyncAemErrorMessageRqst,
	CreateAemComponentRqst,
	CreateAemComponentResp,
	DeleteAemComponentPath,
	ListAemComponentsResp,
	ListAemComponentsQuery,
	UpdateAemComponentRqst,
	UpdateAemComponentResp,
	CreateAemErrorMessageRqst,
	CreateAemErrorMessageResp,
	CreateAemErrorMessagePath,
	DeleteAemErrorMessagePath,
	ListAemErrorMessagesResp,
	ListAemErrorMessagesQuery,
	ListAemErrorMessagesPath,
	UpdateAemErrorMessageRqst,
	UpdateAemErrorMessageResp,
	UpdateAemErrorMessagePath,
	CreateAemComponentWithMessagesRqst,
	CreateAemComponentWithMessagesResp
} from './api-aem';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class AemApiService extends BaseService {
	private static AemApiEndpoint = 'aemApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Backsyncs an AEM error message for a component to the legacy tables.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The PubAemErrorMessageRecord is supplied and valid.
	* <br/>Post-condition:
	* <br/>1. The AEM error message and component are backsynced to the legacy tables.
	* <br/>
	* <br/>
	*/
	public backsyncAemErrorMessage(request: BacksyncAemErrorMessageRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/error-messages/backsync'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Creates a component for application exception messages.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid component code is provided.
	*/
	public createAemComponent(request: CreateAemComponentRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateAemComponentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/components'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Deletes a component for application exception messages.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid component code is provided.
	*/
	public deleteAemComponent(
							   pathParams: DeleteAemComponentPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/components/{componentCd}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Lists all components along with their descriptions and origin server IDs.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. No input is required.
	*/
	public listAemComponents(
							   queryParams: ListAemComponentsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListAemComponentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/components'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Updates the description for a component for application exception messages.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid component code and description are provided.
	*/
	public updateAemComponent(request: UpdateAemComponentRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateAemComponentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/components'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Creates an error message for a component.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. All fields that belong to ErrorMessage are required along with the component code.
	*/
	public createAemErrorMessage(request: CreateAemErrorMessageRqst,
							   pathParams: CreateAemErrorMessagePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateAemErrorMessageResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/components/{componentCd}/error-messages'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Deletes an error message for a component.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The component code, return code, and reason code are required.
	*/
	public deleteAemErrorMessage(
							   pathParams: DeleteAemErrorMessagePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/components/{componentCd}/error-messages/{returnCd}/{reasonCd}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* Lists all error messages by component.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The component code is required.  The return and reason codes are optional.
	*/
	public listAemErrorMessages(
							   pathParams: ListAemErrorMessagesPath,
							   queryParams: ListAemErrorMessagesQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListAemErrorMessagesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/components/{componentCd}/error-messages'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Updates an error message for a component.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. The following fields are mandatory:
	* <br/>- componentCd
	* <br/>- returnCd
	* <br/>- reasonCd
	*/
	public updateAemErrorMessage(request: UpdateAemErrorMessageRqst,
							   pathParams: UpdateAemErrorMessagePath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<UpdateAemErrorMessageResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/components/{componentCd}/error-messages/{returnCd}/{reasonCd}'),
			{
		    	pathParams: pathParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Creates a component along with error messages for application exception messages.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Component and one or more error messages are provided.
	*/
	public createAemComponentWithMessages(request: CreateAemComponentWithMessagesRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<CreateAemComponentWithMessagesResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/components/error-messages'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(AemApiService.AemApiEndpoint);
	}
}
