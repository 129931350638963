import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class ConditioningService {

  private _emailSplitPattern = '[\\s,;]';

  /**
   * condition time to two digit time
   */
  public padTime(value: number): string {

    if ((value !== undefined) && (value !== null) && (value % 1 === 0) && (value < 60) && (value >= 0)) {
      const retValue: string = '0' + value;
      return retValue.slice(-2);
    }
    return undefined;
  }

  /**
   * splits a zip-zipext into their component parts.
   * @param input
   */
  public parseZipParts(input: string): { zip: string, zip4: string } {
    if (!input) {
      input = '';
    }
    const ret = {zip: '', zip4: ''};
    const index = input.indexOf('-');
    if ( index >= 0 ) {
      ret.zip = input.substr(0, index);
      ret.zip4 = input.substr(index + 1);
    } else if ( input.length > 5 && Number.isInteger(Number.parseInt(input)) ) {
      ret.zip = input.substr(0, 5);
      ret.zip4 = input.substr(5);
    } else {
      ret.zip = input;
    }
    return ret;
  }

  /**
   * splits a name by spaces returning the first element as the first name
   * and the reset of the elements merged back together as the last name.
   * @param name
   */
  public parseNames(name: string): { firstName: string, lastName: string} {
    if (!name) {
      name = '';
    }
    const nameParts = {firstName: '', lastName: ''};
      const names = name.split(' ');
      if (names && names.length > 0) {
        nameParts.firstName = names[0];
        delete names[0];
        nameParts.lastName = names.join(' ').trim();
      } else {
        nameParts.firstName = name;
      }
    return nameParts;
  }

  public conditionProNumber(proNumber = '', digits = 9) {
    if ( proNumber === '' || proNumber === null ) {
      return '';
    }
    proNumber = this.clean(proNumber);
    proNumber = this.convert(proNumber, digits);
    return proNumber;
  }
  public removeHtmlFromString(input: string, tagExceptions?: string[]): string {

    let exceptionString = '[^<>]{1,}';
    if (tagExceptions) {
      tagExceptions.forEach( tag => {
        let modifiedTag = tag;
        if (!modifiedTag.startsWith('<')) {
          modifiedTag = `<${modifiedTag}`;
        }
        if (!modifiedTag.endsWith('>')) {
          modifiedTag = `${modifiedTag}>`;
        }
        exceptionString = `${exceptionString}[^${modifiedTag}]{1,}`;
      });
    }
    const regexp = new RegExp(`<{1}${exceptionString}>{1}`, 'g');
    return input.replace(regexp, '');
  }

  public conditionEmailGroup(inputValue: string): string[] {
    if (!inputValue) {
      return undefined;
    }
    return inputValue.split(new RegExp(this._emailSplitPattern, 'g')).map(item => item.trim()).filter(item => !!item);
  }

  private clean(proNumber) {
    return proNumber.replace(/\D/g, '');
  }

  private convert(proNumber = '', digits = 9) {
    let part1, part2, trim, trimmed;
    trim = _.partialRight(this.trim.bind(this), '0', 'g', digits);
    trimmed = trim(proNumber);

    if (trimmed.length < 9) { // if it had a leading 0 as part of the real number
      if (trimmed.length < 8) {
        trimmed = `00${trimmed}`;
      } else {
        trimmed = `0${trimmed}`;
      }
    }
    part1 = trimmed.slice(0, 3);
    part2 = trimmed.slice(3);
    part2 = (part2.length > 6) ? trim(part2) : part2;

    let retValue = '';
    if ( digits === 11 ) {
      retValue = `0${part1}0${part2}`;
    } else if ( digits === 10 ) {
      retValue = `${part1}-${part2}`;
    } else {
      retValue = part1.concat(part2);
    }

    return retValue;
  }
  private trim(string = '', what = ',', flags = 'g') {
    let escaped, find;

    escaped = what.replace(/[\[\](){}?*+\^$\\.|\-]/g, '\\$&');
    find = new RegExp('^[' + escaped + ']', flags);

    return string.replace(find, '');
  }

  private sliceError(errorMsg) {
    if ( typeof errorMsg === 'string' ) {
      return errorMsg.replace(/.*?:/, '').slice(0, -1).trim();
    } else {
      return '';
    }
  }
}

