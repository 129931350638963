import {RuleActiveTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class RuleActiveTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in RuleActiveTypeCd ) {
      this._values.push(RuleActiveTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): RuleActiveTypeCd {

    for ( const obj in RuleActiveTypeCd ) {
      if (RuleActiveTypeCd[obj] === value){
        return RuleActiveTypeCd[obj] as RuleActiveTypeCd;
      }
    }
  }
}

const RuleActiveTypeCdHelper = new RuleActiveTypeCdHelperEnumHelperClass();
export default RuleActiveTypeCdHelper;
