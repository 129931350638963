import {MoveModeCd} from '../';
import {EnumHelper} from './enum-helper';

export class MoveModeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in MoveModeCd ) {
      this._values.push(MoveModeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): MoveModeCd {

    for ( const obj in MoveModeCd ) {
      if (MoveModeCd[obj] === value){
        return MoveModeCd[obj] as MoveModeCd;
      }
    }
  }
}

const MoveModeCdHelper = new MoveModeCdHelperEnumHelperClass();
export default MoveModeCdHelper;
