/**
 * DocumentManagement API
 * Version: 1.0
 * Build: Manual
 */


export class ArchiveDocumentRqst {
	/**
	 * Required: true
	 * Array: false
	 */
	corpCode:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	docClass:string = undefined;
	/**
	 * Document data to be archived
	 * Required: true
	 * Array: false
	 */
	inputFile:ImageFile = undefined;
	/**
	 * Metadata for the file to be archived
	 * Required: true
	 * Array: false
	 */
	archiveRequest:DmsArchiveRequest = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	dmsAuth:string = undefined;
}
export class ArchiveDocumentResp {
	/**
	 * Required: false
	 * Array: false
	 */
	documentInfo:DocumentId = undefined;
}
export class GetArchivedDocumentRqst {
	/**
	 * The Corporation Code of the  documents being located
	 * Required: true
	 * Array: false
	 */
	corpCode:string = undefined;
	/**
	 * the document class for the document(s) being located
	 * Required: true
	 * Array: false
	 */
	docClass:string = undefined;
	/**
	 * The timestamp in microseconds in Tandem time format that the document was originally archived into DMS.
	 * Required: true
	 * Array: false
	 */
	docArchiveTimestamp:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	dmsAuth:string = undefined;
	/**
	 * Value= blackwhite
	 * Required: false
	 * Array: false
	 */
	rendition:string = undefined;
}
export class GetArchivedDocumentResp {
	/**
	 * Required: false
	 * Array: false
	 */
	document:string = undefined;
}
export class RetrieveDmsAuthTokenRqst {
}
export class RetrieveDmsAuthTokenRqst_OLD {
	/**
	 * Required: false
	 * Array: false
	 */
	grant_type:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	username:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	password:string = undefined;
}
export class RetrieveDmsAuthTokenResp {
	/**
	 * Required: false
	 * Array: false
	 */
	access_token:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	token_type:string = undefined;
	/**
	 * Expiration time in seconds of the access token
	 * Required: false
	 * Array: false
	 */
	expires_in:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	scope:string = undefined;
}
/**
 * Request message to create the Workflow Request in DMS system
 */
export class CreateWorkFlowRequestRqst {
	/**
	 * Required: true
	 * Array: true
	 */
	requestObjects:RequestObject[] = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	appWorkArea:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	activityName:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	dmsAuth:string = undefined;
}
export class CreateWorkFlowRequestResp {
	/**
	 * Required: false
	 * Array: false
	 */
	requestNumber:string = undefined;
}
/**
 * Request message to search for a Document archived in DMS
 */
export class SearchDmsDocumentRqst {
	/**
	 * Required: true
	 * Array: false
	 */
	corpCode:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	searchTags:string[] = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	searchString:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	minDateTime:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	maxDateTime:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	dmsAuth:string = undefined;
}
export class SearchDmsDocumentResp {
	/**
	 * Required: false
	 * Array: true
	 */
	documentInfo:DocumentSearch[] = undefined;
}
export class ListActiveRequestsByQueueRqst {
	/**
	 * Required: true
	 * Array: false
	 */
	queueName:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	activityName:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	flowName:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	dmsAuth:string = undefined;
}
export class ListActiveRequestsByQueueResp {
	/**
	 * Total count of requests
	 * Required: false
	 * Array: false
	 */
	count:number = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	requests:RequestId[] = undefined;
}
/**
 * Input data to retrieve 1 or more imaged documents for a single Pro number from DMS
 */
export class GetShipmentImagedDocumentsRqst {
	/**
	 * Business Id of the Shipment
	 * Required: true
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * pdf , jpg or tiff
	 * Required: false
	 * Array: false
	 */
	imageFormat:string = undefined;
	/**
	 * type of image: BL (Bill of lading, DR(Delivery Receipt),CUST(Customs document),DRRR(Delivery Return Receipt), IR(Inspection Report), LOA(Letter of Authority),NCIC(NMFC Certificate),WI(Weight and Correction Certificate), WRFO(Weight and Research Photos)
	 * Required: false
	 * Array: true
	 */
	imageType:string[] = undefined;
	/**
	 * Indicates if the multiPart response should be returned. If not provided then this is assumed to be true.
	 * Required: false
	 * Array: false
	 */
	multiPartResp:boolean = undefined;
	/**
	 * The timestamp in microseconds in Tandem time format that the document was originally archived into DMS.
	 * Required: false
	 * Array: false
	 */
	docArchiveTimestamp:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	dmsAuth:string = undefined;
}
/**
 * Output data to retrieve a list of imaged documents from DMS
 */
export class GetShipmentImagedDocumentsResp {
	/**
	 * Required: false
	 * Array: true
	 */
	imagedDocument:ImagedDocument[] = undefined;
}
export class ProcessShipmentCustomsDocumentsRqst {
	/**
	 * Business Id of the Shipment
	 * Required: true
	 * Array: false
	 */
	proNbr:string = undefined;
	/**
	 * Required: true
	 * Array: true
	 */
	documents:DocumentCdt[] = undefined;
}
export class ProcessShipmentCustomsDocumentsResp {
	/**
	 * TIFF Binary data for customs documents
	 * Required: false
	 * Array: false
	 */
	documentData:string = undefined;
}
/**
 * Request message to Associate additional index information with a previously archived document
 */
export class AddIndexToDocumentRqst {
	/**
	 * The Corporation Code of the  documents being located
	 * Required: true
	 * Array: false
	 */
	corpCode:string = undefined;
	/**
	 * the document class for the document(s) being located
	 * Required: true
	 * Array: false
	 */
	docClass:string = undefined;
	/**
	 * The timestamp in microseconds in Tandem time format that the document was originally archived into DMS.
	 * Required: true
	 * Array: false
	 */
	docArchiveTimestamp:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	dmsAuth:string = undefined;
	/**
	 * Required: true
	 * Array: true
	 */
	indices:DocumentIndex[] = undefined;
}
/**
 * Response message
 */
export class AddIndexToDocumentResp {
	/**
	 * Response status code. Eg. 200, 400 or 404
	 * Required: true
	 * Array: false
	 */
	code:string = undefined;
}
/**
 * Input data to retrieve 1 or more imaged documents for a single Pro number from DMS
 */
export class GetDocumentRqst {
	/**
	 * Required: true
	 * Array: false
	 */
	corpCode:string = undefined;
	/**
	 * type of image: BL (Bill of lading, DR(Delivery Receipt),CUST(Customs document),DRRR(Delivery Return Receipt), IR(Inspection Report), LOA(Letter of Authority),NCIC(NMFC Certificate),WI(Weight and Correction Certificate), WRFO(Weight and Research Photos)
	 * Required: true
	 * Array: false
	 */
	docClass:string = undefined;
	/**
	 * The timestamp in microseconds in Tandem time format that the document was originally archived into DMS.
	 * Required: true
	 * Array: false
	 */
	docArchiveTimestamp:string = undefined;
	/**
	 * pdf , jpg or tiff
	 * Required: true
	 * Array: false
	 */
	docFormat:string = undefined;
	/**
	 * Indicates if the multiPart response should be returned. If not provided then this is assumed to be true.
	 * Required: false
	 * Array: false
	 */
	multiPartResp:boolean = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	dmsAuth:string = undefined;
}
/**
 * Output data to retrieve a list of imaged documents from DMS
 */
export class GetDocumentResp {
	/**
	 * Binary data for documents.
	 * Required: false
	 * Array: false
	 */
	documentData:string = undefined;
	/**
	 * Name of the file.
	 * Required: false
	 * Array: false
	 */
	fileName:string = undefined;
	/**
	 * Mime type representing the contents of the file.
	 * Required: false
	 * Array: false
	 */
	contentType:string = undefined;
}
/**
 * Information about the documents archived in the DMS system.
 */
export class DocumentInfoDetail {
	/**
	 * Required: false
	 * Array: false
	 */
	corpCode:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	docClass:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	docNum:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	displayName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tmSec:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tmMin:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tmHour:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tmMDay:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tmMon:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tmYear:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tmWday:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tmYday:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	tmlsdst:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	archiveMsecs:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	vol:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	subvol:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	offset:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	mediaErr:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	formatErr:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	isOffline:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	presentErr:number = undefined;
}
/**
 * Information about the documents archived in the DMS system.
 */
export class DocumentInfo {
	/**
	 * Required: false
	 * Array: false
	 */
	documentUrl:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	documentFolder:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	documentName:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	createdDate:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	modifiedDate:Date = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	documentAuthor:string = undefined;
}
/**
 * Basic Information to Identify a unique document
 */
export class DocumentId {
	/**
	 * The Corporation Code of the  documents being located
	 * Required: false
	 * Array: false
	 */
	corpCode:string = undefined;
	/**
	 * the document class for the document(s) being located
	 * Required: false
	 * Array: false
	 */
	docClass:string = undefined;
	/**
	 * The timestamp in microseconds in Tandem time format that the document was originally archived into DMS.
	 * Required: false
	 * Array: false
	 */
	docArchiveTimestamp:string = undefined;
}
/**
 * Basic Information to Identify a unique document
 */
export class DocumentCdt {
	/**
	 * The Corporation Code of the  documents being located
	 * Required: false
	 * Array: false
	 */
	corpCode:string = undefined;
	/**
	 * the document class for the document(s) being located
	 * Required: false
	 * Array: false
	 */
	docClass:string = undefined;
	/**
	 * The timestamp in microseconds in Tandem time format that the document was originally archived into DMS.
	 * Required: false
	 * Array: false
	 */
	timestamp:string = undefined;
}
/**
 * Information about the index to be added
 */
export class DocumentIndex {
	/**
	 * Optional. May be left blank. Only when an index will not be used as part a document number in a locate (searching for a document by corporation code, document class and document number) that it should have a non-blank tag value.
	 * Required: false
	 * Array: false
	 */
	tag:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	value:string = undefined;
}
export class MatchedDocInfo {
	/**
	 * Required: false
	 * Array: false
	 */
	documentInfo:DocumentId = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	relevanceValue:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	matchingIndex:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	referenceIndex:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	additionalInfo:string = undefined;
}
export class DmsIndex {
	/**
	 * Required: true
	 * Array: false
	 */
	tag:string = undefined;
	/**
	 * Required: true
	 * Array: false
	 */
	value:string = undefined;
}
export class DmsArchiveRequest {
	/**
	 * Required: true
	 * Array: false
	 */
	docNumber:string = undefined;
	/**
	 * Required: false
	 * Array: true
	 */
	indices:DmsIndex[] = undefined;
}
export class RequestObject {
	/**
	 * The Corporation Code of the  documents being located
	 * Required: false
	 * Array: false
	 */
	corpCode:string = undefined;
	/**
	 * the document class for the document(s) being located
	 * Required: false
	 * Array: false
	 */
	docClass:string = undefined;
	/**
	 * Timestamp
	 * Required: false
	 * Array: false
	 */
	timestamp:string = undefined;
}
export class DocumentSearch {
	/**
	 * Required: false
	 * Array: false
	 */
	cdt:DocumentCdt = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	timestampISO:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	relevanceValue:number = undefined;
}
export class RequestId {
	/**
	 * Required: false
	 * Array: false
	 */
	requestId:number = undefined;
}
/**
 * Information about Imaged Documents for a Pro Number
 */
export class ImagedDocument {
	/**
	 * unique business identifier of the shipment
	 * Required: false
	 * Array: false
	 */
	proNumber:string = undefined;
	/**
	 * type of image: BL (Bill of lading, DR(Delivery Receipt),CUST(Customs document),DRRR(Delivery Return Receipt), IR(Inspection Report), LOA(Letter of Authority),NCIC(NMFC Certificate),WI(Weight and Correction Certificate), WRFO(Weight and Research Photos)
	 * Required: false
	 * Array: false
	 */
	imageType:string = undefined;
	/**
	 * image format - pdf, jpg,png, tiff
	 * Required: false
	 * Array: false
	 */
	imageFormat:string = undefined;
	/**
	 * image files
	 * Required: false
	 * Array: true
	 */
	imageFiles:ImageFile[] = undefined;
	/**
	 * Date when the document was scanned and imaged
	 * Required: false
	 * Array: false
	 */
	scanDate:Date = undefined;
}
/**
 * Information about Imaged Documents for a Pro Number
 */
export class DmsDocument {
	/**
	 * type of image: BL (Bill of lading, DR(Delivery Receipt),CUST(Customs document),DRRR(Delivery Return Receipt), IR(Inspection Report), LOA(Letter of Authority),NCIC(NMFC Certificate),WI(Weight and Correction Certificate), WRFO(Weight and Research Photos)
	 * Required: false
	 * Array: false
	 */
	docClass:string = undefined;
	/**
	 * image format - pdf, jpg,png, tiff
	 * Required: false
	 * Array: false
	 */
	docFormat:string = undefined;
	/**
	 * image files
	 * Required: false
	 * Array: false
	 */
	imageFiles:ImageFile = undefined;
	/**
	 * Date when the document was scanned and imaged
	 * Required: false
	 * Array: false
	 */
	scanDate:Date = undefined;
}
export class ImageFile {
	/**
	 * Name of the file.
	 * Required: false
	 * Array: false
	 */
	fileName:string = undefined;
	/**
	 * Mime type representing the contents of the file.
	 * Required: false
	 * Array: false
	 */
	contentType:string = undefined;
	/**
	 * Base 64 Formatted image data.
	 * Required: false
	 * Array: false
	 */
	base64Data:string = undefined;
}
export class DmsToken {
	/**
	 * Required: false
	 * Array: false
	 */
	access_token:string = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	token_type:string = undefined;
	/**
	 * Expiration time in seconds of the access token
	 * Required: false
	 * Array: false
	 */
	expires_in:number = undefined;
	/**
	 * Required: false
	 * Array: false
	 */
	scope:string = undefined;
}

/**
 * Query parameters for getArchivedDocument
 */
export class GetArchivedDocumentQuery {
	rendition: string = undefined;
}

/**
 * Query parameters for getShipmentImagedDocuments
 */
export class GetShipmentImagedDocumentsQuery {
	imageFormat: string = undefined;
	imageType: string[] = undefined;
	multiPartResp: string = undefined;
	docArchiveTimestamp: string = undefined;
}

/**
 * Query parameters for getDocument
 */
export class GetDocumentQuery {
	multiPartResp: string = undefined;
}


/**
 * Path parameters for getArchivedDocument
 */
export class GetArchivedDocumentPath {
	corpCode: string = undefined;
	docClass: string = undefined;
	docArchiveTimestamp: string = undefined;
}

/**
 * Path parameters for archiveDocument
 */
export class ArchiveDocumentPath {
	corpCode: string = undefined;
	docClass: string = undefined;
}

/**
 * Path parameters for createWorkFlowRequest
 */
export class CreateWorkFlowRequestPath {
	queueName: string = undefined;
	activityName: string = undefined;
}

/**
 * Path parameters for searchDmsDocument
 */
export class SearchDmsDocumentPath {
	corpCode: string = undefined;
}

/**
 * Path parameters for listActiveRequestsByQueue
 */
export class ListActiveRequestsByQueuePath {
	queueName: string = undefined;
	activityName: string = undefined;
	flowName: string = undefined;
}

/**
 * Path parameters for getShipmentImagedDocuments
 */
export class GetShipmentImagedDocumentsPath {
	proNbr: string = undefined;
}

/**
 * Path parameters for processShipmentCustomsDocuments
 */
export class ProcessShipmentCustomsDocumentsPath {
	proNbr: string = undefined;
}

/**
 * Path parameters for addIndexToDocument
 */
export class AddIndexToDocumentPath {
	corpCode: string = undefined;
	docClass: string = undefined;
	docArchiveTimestamp: string = undefined;
}

/**
 * Path parameters for getDocument
 */
export class GetDocumentPath {
	corpCode: string = undefined;
	docClass: string = undefined;
	docArchiveTimestamp: string = undefined;
	docFormat: string = undefined;
}


