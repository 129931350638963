import {CustomerStatusCd} from '../';
import {EnumHelper} from './enum-helper';

export class CustomerStatusCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in CustomerStatusCd ) {
      this._values.push(CustomerStatusCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): CustomerStatusCd {

    for ( const obj in CustomerStatusCd ) {
      if (CustomerStatusCd[obj] === value){
        return CustomerStatusCd[obj] as CustomerStatusCd;
      }
    }
  }
}

const CustomerStatusCdHelper = new CustomerStatusCdHelperEnumHelperClass();
export default CustomerStatusCdHelper;
