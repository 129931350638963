import {LicenseEquipmentOwnedLeaseCd} from '../';
import {EnumHelper} from './enum-helper';

export class LicenseEquipmentOwnedLeaseCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LicenseEquipmentOwnedLeaseCd ) {
      this._values.push(LicenseEquipmentOwnedLeaseCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LicenseEquipmentOwnedLeaseCd {

    for ( const obj in LicenseEquipmentOwnedLeaseCd ) {
      if (LicenseEquipmentOwnedLeaseCd[obj] === value){
        return LicenseEquipmentOwnedLeaseCd[obj] as LicenseEquipmentOwnedLeaseCd;
      }
    }
  }
}

const LicenseEquipmentOwnedLeaseCdHelper = new LicenseEquipmentOwnedLeaseCdHelperEnumHelperClass();
export default LicenseEquipmentOwnedLeaseCdHelper;
