import {ApiContractImplApiMethodCd} from '../';
import {EnumHelper} from './enum-helper';

export class ApiContractImplApiMethodCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in ApiContractImplApiMethodCd ) {
      this._values.push(ApiContractImplApiMethodCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): ApiContractImplApiMethodCd {

    for ( const obj in ApiContractImplApiMethodCd ) {
      if (ApiContractImplApiMethodCd[obj] === value){
        return ApiContractImplApiMethodCd[obj] as ApiContractImplApiMethodCd;
      }
    }
  }
}

const ApiContractImplApiMethodCdHelper = new ApiContractImplApiMethodCdHelperEnumHelperClass();
export default ApiContractImplApiMethodCdHelper;
