import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
  AuthModule,
  AUTH_SERVICE,
  PUBLIC_FALLBACK_PAGE_URI,
  PROTECTED_FALLBACK_PAGE_URI
} from 'ngx-auth';

import { LoginService } from './services/login.service';
import { AuthenticationService } from './services/authentication.service';
import {
  MatButtonModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSnackBarModule
} from '@angular/material';
import { LOGIN_CONFIG } from './services/login-config';
import { LoginComponent } from './login.component';
import { ConfigManagerModule } from '@xpo-ltl/config-manager';


@NgModule( {
  imports: [
    AuthModule,
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatSnackBarModule,
    ConfigManagerModule
  ],
  entryComponents: [
    LoginComponent
  ],
  providers: [
    { provide: PROTECTED_FALLBACK_PAGE_URI, useValue: '/' },
    { provide: PUBLIC_FALLBACK_PAGE_URI, useValue: '/' },
    { provide: AUTH_SERVICE, useClass: AuthenticationService },
  ],
  declarations: [LoginComponent]
} )

export class LoginModule {
}

export { LOGIN_CONFIG, ILoginConfig } from './services/login-config';


