import {LicenseEquipmentTypeCd} from '../';
import {EnumHelper} from './enum-helper';

export class LicenseEquipmentTypeCdHelperEnumHelperClass extends EnumHelper {

  public constructor() {
  	super();
    for ( const obj in LicenseEquipmentTypeCd ) {
      this._values.push(LicenseEquipmentTypeCd[obj]);
      this._keys.push(obj);
    }
  }

  public toEnum(value: string): LicenseEquipmentTypeCd {

    for ( const obj in LicenseEquipmentTypeCd ) {
      if (LicenseEquipmentTypeCd[obj] === value){
        return LicenseEquipmentTypeCd[obj] as LicenseEquipmentTypeCd;
      }
    }
  }
}

const LicenseEquipmentTypeCdHelper = new LicenseEquipmentTypeCdHelperEnumHelperClass();
export default LicenseEquipmentTypeCdHelper;
