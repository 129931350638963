
export enum AddressTypeCd {
	BILL_TO = 'Bill-To',
	CLAIMANT = 'Claimant',
	COD_REMIT_TO_PARTY = 'COD Remit To Party',
	CONSIGNEE = 'Consignee',
	CUSTOM_BROKER = 'Custom Broker',
	EMERGENCY_CONTACT = 'Emergency Contact',
	FINANCIAL_LOCATION = 'Financial Location',
	PAYEE = 'Payee',
	PICKUP_CONTACT = 'Pickup Contact',
	PICKUP_REQUESTER = 'Pickup Requester',
	SHIPPER = 'Shipper'}

