import { InjectionToken } from '@angular/core';

export interface ILoginConfig {
  appNames: string[];
  apiUrl: string;
  secretTokens: string[];
  scopeOptions: string[];
}

export const LOGIN_CONFIG = new InjectionToken<ILoginConfig>( 'login.config' );

